import { Modal } from "antd";
// import Link from "next/link";
import React, { useLayoutEffect, useState } from "react";
import Slider from "react-slick";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import { Link, useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import CoustomImg2 from "../CoustomImg/CoustomImg2";
// import { URL } from "@/Redux/common/url";

function DonationList({ homedata }) {
  const get_home_all_pro = homedata;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videodetal, setvideodetal] = useState({});
  const navigate = useNavigate();
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const recentlisting = get_home_all_pro?.donationlist
    ? get_home_all_pro?.donationlist
    : [];

  var settings = {
    dots: false,
    infinite: true,
    // autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.donationlist?.length == 1
        ? 1
        : get_home_all_pro?.donationlist?.length > 6
          ? 6
          : get_home_all_pro?.donationlist?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.donationlist?.length == 1
              ? 1
              : get_home_all_pro?.donationlist?.length > 4
                ? 4
                : get_home_all_pro?.donationlist?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.donationlist?.length == 1
              ? 1
              : get_home_all_pro?.donationlist?.length > 3
                ? 2
                : get_home_all_pro?.donationlist?.length - 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.donationlist?.length == 1
              ? 1
              : get_home_all_pro?.donationlist?.length > 2
                ? 2
                : get_home_all_pro?.donationlist?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <Modal
        title="Video"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={800}
      >
        <iframe
          width="260"
          height="280"
          src={videodetal?.link}
          // src={"https://www.youtube.com/embed/LGtw6rpmXEw?si=lLE4ySa4rvreSq6t"}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal>
      <div className="ps-product-list ps-clothings">
        <div className="ps-container">
          <div className="ps-section__header">
            <div className="ps-block--countdown-deal">
              <div className="ps-block__left">
                <b>
                  {" "}
                  <h3>Donation</h3>
                </b>
              </div>
            </div>
            <Link to="/all-donation-type">View all</Link>
          </div>

          <div className="  mt-10">
            {recentlisting?.length > 0 && (
              <Slider {...settings}>
                {recentlisting &&
                  recentlisting?.map((data, i) => {
                    return (
                      <div className="videoBox " key={i}>
                        <div className="card newbx">
                        <Link to={`/donation-details/${data?.pro_sulg}`}>
                          <CoustomImg2
                            url={
                              data?.featureImage
                                ? URL.API_BASE_URL + data?.featureImage
                                : ""
                            }
                            altkey={data?.title}
                            w1={500}
                            w2={300}
                            h1={110}
                            h2={140}
                          />
                          </Link>
                          {/* <div
                            className="playIcon"
                            onClick={() => {
                              window?.open(data?.link, "_blank");
                            }}
                          ></div> */}
                          <div className="ps-product__container text-center donationtextBox">
                            <div className="ps-product__content">
                              <TruncateText
                                text={data?.title}
                                //  maxLength={20}
                                maxLength={windowWidth > 600 ? 28 : 15}
                              />
                              <br />
                              {/* <TruncateText
                                text={data?.description}
                                maxLength={20}
                              /> */}

                              <button
                                className="btn btn-light"
                                onClick={() => {
                                  navigate(`/donation-details/${data?.pro_sulg}`);
                                }}
                              >
                                Donate Now
                              </button>
                            </div>
                            {/* <div className="ps-product__content hover">
                            
                            </div> */}
                          </div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DonationList;
