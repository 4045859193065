// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate } from "react-router-dom";

function CartComponent() {
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const router = useNavigate();
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);
  const totalSubtotalshipping = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.delivery_charges;
  }, 0);
  const totalSubtotalPricegst = get_all_cart_itme.reduce((total, obj) => {
    return total + (obj.subtotalprice * obj?.gst) / 100;
  }, 0);

  console.log(totalSubtotalPricegst);
  const shippingCharge = totalSubtotalshipping;

  const totalAmount = totalSubtotalPrice + shippingCharge;
  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));
    } else {
      setValue("");
      setValuevendor("");
    }
  }, []);

  const tokenhave = value || valuevendor ? true : false;

  const dispatch = useDispatch();

  const hendleCartClear = () => {
    if (tokenhave) {
      dispatch(allapiAction.removeAllCartItem());
      return () => {};
    } else {
      localStorage.setItem("cart_product", JSON.stringify([]));
      dispatch(allapiAction.getcartProduct({ data: [] }));
      return () => {};
    }
  };

  const removeCart = (e, color, size) => {
    if (tokenhave) {
      dispatch(
        allapiAction.removeCartItem({
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      var lists = get_all_cart_itme.filter((x) => {
        return x.product_id != e;
      });

      dispatch(
        allapiAction.getcartProduct({
          data: lists,
        })
      );
      localStorage.setItem("cart_product", JSON.stringify(lists));
      return () => {};
    }
  };

  const addItmeCount = (e, color, size) => {
    if (tokenhave) {
      dispatch(
        allapiAction.addItemCartCount({
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      const finalresult =
        get_all_cart_itme &&
        get_all_cart_itme?.map((data) => {
          data.item = data?.product_id == e ? data?.item + 1 : data?.item;
          return data;
        });
      dispatch(
        allapiAction.getcartProduct({
          data: finalresult,
        })
      );
      localStorage.setItem("cart_product", JSON.stringify(finalresult));
    }
  };
  const removeItmeCount = (e, color, size) => {
    if (tokenhave) {
      dispatch(
        allapiAction.removeItemCartCount({
          product_id: e,
          color: color,
          size: size,
        })
      );
      return () => {};
    } else {
      const secondObj = get_all_cart_itme.find((item) => item._id == e);
      if (secondObj?.item > 1) {
        const finalresult =
          get_all_cart_itme &&
          get_all_cart_itme?.map((data) => {
            data.item = data?.product_id == e ? data?.item - 1 : data?.item;
            return data;
          });
        dispatch(
          allapiAction.getcartProduct({
            data: finalresult,
          })
        );
        localStorage.setItem("cart_product", JSON.stringify(finalresult));
      } else {
        toast.error("minimum quntity 1 select");
      }
    }
  };

  return (
    <div>
      <div className="ps-section--shopping ps-shopping-cart">
        <div className="container-fluid">
          <div className="ps-section__header2">
            <h1>Shopping Cart</h1>
          </div>
          <section class="bg-light my-5">
            <div class="container-fluid">
              <div class="row">
                {/* <!-- cart --> */}
                <div class="col-lg-9">
                  <div class="card border shadow-0">
                    <div class="m-4">
                      <h4 class="card-title mb-4">Your shopping cart</h4>

                      {get_all_cart_itme &&
                        get_all_cart_itme?.map((data, i) => {
                          console.log(data);
                          return (
                            <div class="row gy-3 mb-4">
                              <div class="col-lg-8">
                                <div class="me-lg-5">
                                  <div class="d-flex">
                                    <img
                                      src={
                                        URL?.API_BASE_URL + data?.featureImage
                                      }
                                      class="border rounded me-3"
                                      style={{ width: "96px", height: "96px" }}
                                    />
                                    <div class="">
                                      <a href="#" class="nav-link">
                                        {data?.title}
                                      </a>
                                      <p class="text-muted ml-10">
                                        {" "}
                                        {data?.size},{" "}
                                        {data?.productcolorId?.color}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-2 col-sm-6 col-12 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                                <div class="">
                                  <div className="form-group--number">
                                    <button
                                      className="up"
                                      onClick={() => {
                                        addItmeCount(
                                          data?.productId?._id,
                                          data?.productcolorId?._id,
                                          data?._id
                                        );
                                      }}
                                    >
                                      +
                                    </button>
                                    <button
                                      className="down"
                                      onClick={() => {
                                        removeItmeCount(
                                          data?.productId?._id,
                                          data?.productcolorId?._id,
                                          data?._id
                                        );
                                      }}
                                    >
                                      -
                                    </button>
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="1"
                                      value={data?.item}
                                    />
                                  </div>
                                </div>
                                <div class="">
                                  <text class="h6 ml-6">
                                    ₹ {data?.subtotalprice}
                                  </text>{" "}
                                  <br />
                                  <small class="text-muted text-nowrap">
                                    {" "}
                                    ₹ {data?.afterdiscountprice}/ per item{" "}
                                  </small>
                                </div>
                              </div>
                              <div class="col-lg col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
                                <div class="float-md-end">
                                  {/* <a
                            href="#!"
                            class="btn btn-light border px-2 icon-hover-primary"
                          >
                            <i class="fas fa-heart fa-lg px-1 text-secondary"></i>
                          </a> */}
                                  {/* <a
                                    href="#"
                                    class="btn btn-light border text-danger icon-hover-danger"
                                  >
                                    {" "}
                                    Remove
                                  </a> */}
                                  <a
                                    href="#"
                                    onClick={() => {
                                      removeCart(
                                        data?.productId?._id,
                                        data?.productcolorId?._id,
                                        data?._id
                                      );
                                    }}
                                    style={{
                                      color: "white",
                                      backgroundColor: "red",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    Remove
                                    {/* <i className="icon-cross"></i> */}
                                  </a>
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })}
                      {/* {
                      get_all_cart_itme && get_all_cart_itme?.map((data) => {
                       return(
                        <div class="row gy-3 mb-4">
                      <div class="col-lg-5">
                        <div class="me-lg-5">
                          <div class="d-flex">
                            <img
                              src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/11.webp"
                              class="border rounded me-3"
                              style={{ width: "96px", height: "96px" }}
                            />
                            <div class="">
                              <a href="#" class="nav-link">
                                Winter jacket for men and lady
                              </a>
                              <p class="text-muted">Yellow, Jeans</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                        <div class="">
                          <select
                            style={{ width: "100px" }}
                            class="form-select me-4"
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                        </div>
                        <div class="">
                          <text class="h6">$1156.00</text> <br />
                          <small class="text-muted text-nowrap">
                            {" "}
                            $460.00 / per item{" "}
                          </small>
                        </div>
                      </div>
                      <div class="col-lg col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
                        <div class="float-md-end">
                          <a
                            href="#!"
                            class="btn btn-light border px-2 icon-hover-primary"
                          >
                            <i class="fas fa-heart fa-lg px-1 text-secondary"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-light border text-danger icon-hover-danger"
                          >
                            {" "}
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>
                       ) 
                      })
                    } */}
                    </div>

                    <div class="border-top pt-4 mx-4 mb-4">
                      {/* <p>
                      <i class="fas fa-truck text-muted fa-lg"></i> Free
                      Delivery within 1-2 weeks
                    </p>
                    <p class="text-muted">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip
                    </p> */}
                    </div>
                  </div>
                </div>
                {/* <!-- cart -->
      <!-- summary --> */}
                <div class="col-lg-3">
                  <div class="card mb-3 border shadow-0">
                    <div class="card-body">
                      <form>
                        <div class="form-group">
                          <label class="form-label">Have coupon?</label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control border"
                              name=""
                              placeholder="Coupon code"
                            />
                            <button class="btn btn-light border">Apply</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="card shadow-0 border">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <p class="mb-2">Total price:</p>
                        <p class="mb-2">
                          ₹{totalSubtotalPrice - totalSubtotalPricegst}
                        </p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-2">Shipping:</p>
                        <p class="mb-2 text-success">₹{shippingCharge}</p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-2">TAX:</p>
                        <p class="mb-2">₹{totalSubtotalPricegst}</p>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <p class="mb-2">Total price:</p>
                        <p class="mb-2 fw-bold">₹{totalAmount} </p>
                      </div>

                      <div class="mt-3">
                        <Link
                          to={tokenhave ? "/shopping-address" : "/login"}
                          // onClick={() => {
                          //   orderSumit();
                          // }}
                          //         onClick={() =>
                          //   router(tokenhave ? "/shopping-address" : "/login")
                          // }
                          class="btn btn-success w-100 shadow-0 mb-2"
                        >
                          {" "}
                          Proceed to checkout
                        </Link>
                        <Link to="/" class="btn btn-light w-100 border mt-2">
                          {" "}
                          Back to Shop
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- summary --> */}
              </div>
            </div>
          </section>
          {/* <div className="row">
            <div className="col-lg-8">
              <div className="ps-section__content">
                <div className="table-responsive">
                  <table className="table ps-table--shopping-cart ps-table--responsive">
                    <thead>
                      <tr>
                        <th>Product name</th>
                        <th>PRICE</th>
                        <th>QUANTITY</th>
                        <th>TOTAL</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {get_all_cart_itme &&
                        get_all_cart_itme?.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td data-label="Product">
                                <div className="ps-product--cart">
                                  <div className="ps-product__thumbnail">
                                    <a href="product-default.html">
                                      <img
                                        src={
                                          URL?.API_BASE_URL + data?.featureImage
                                        }
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  <div className="ps-product__content">
                                    <a href="product-default.html">
                                      {data?.title}
                                    </a>
                                    
                                  </div>
                                </div>
                              </td>
                              <td className="price" data-label="Price">
                                ₹ {data?.afterdiscountprice}
                              </td>
                              <td data-label="Quantity">
                                <div className="form-group--number">
                                  <button
                                    className="up"
                                    onClick={() => {
                                      addItmeCount(
                                        data?.productId?._id,
                                        data?.productcolorId?._id,
                                        data?._id
                                      );
                                    }}
                                  >
                                    +
                                  </button>
                                  <button
                                    className="down"
                                    onClick={() => {
                                      removeItmeCount(
                                        data?.productId?._id,
                                        data?.productcolorId?._id,
                                        data?._id
                                      );
                                    }}
                                  >
                                    -
                                  </button>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="1"
                                    value={data?.item}
                                  />
                                </div>
                              </td>
                              <td data-label="Total">
                                {" "}
                                ₹{data?.subtotalprice}
                              </td>
                              <td data-label="Actions">
                                <a
                                  href="#"
                                  onClick={() => {
                                    removeCart(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    );
                                  }}
                                >
                                  <i className="icon-cross"></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="ps-section__cart-actions">
                  <a className="ps-btn" href="/">
                    <i className="icon-arrow-left"></i> Back to Shop
                  </a>
                  {get_all_cart_itme.length ? (
                    <Link
                      className="ps-btn ps-btn--outline"
                      to={tokenhave ? "/shopping-address" : "/login"}
                    >
                      Proceed to checkout
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="ps-section__footer">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 "></div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 "></div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 "></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 cardvm">
              <div class=" ">
                <h4 class="mb-3">Summary</h4>

                <div class="row">
                  <div class="col-6 mt-10">
                    <span>Total price</span>
                  </div>
                  <div class="col-6 mt-10 text-right">
                    <span> ₹ {totalSubtotalPrice - totalSubtotalPricegst}</span>
                  </div>
                  <div class="col-6 mt-10">
                    <span>Shiping</span>
                  </div>
                  <div class="col-6 mt-10 text-right">
                    <span> ₹ {shippingCharge} </span>
                  </div>
                  <div class="col-6 mt-10">
                    <span>Tax</span>
                  </div>
                  <div class="col-6 mt-10 text-right">
                    <span> ₹ {totalSubtotalPricegst} </span>
                  </div>
                </div>

                 
                <hr />
                <div class="row">
                  <div class="col-6">
                    <strong>Total Price</strong>
                  </div>
                  <div class="col-6 text-right">
                    <strong>₹ {totalAmount}</strong>
                  </div>
                </div>

                <button
                  class="btn btn-dark btn-block mt-15"
                  onClick={() =>
                    router(tokenhave ? "/shopping-address" : "/login")
                  }
                >
                  Checkout
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CartComponent;
