import React from "react";

function TremsAndCondition() {
  return (
    <div className="container">
      <div className="mt-20"></div>
      <h1>Terms & Conditions</h1>

    <h2>1. Introduction</h2>
    <p>Welcome to Aghoreshwara. These Terms & Conditions govern your use of our e-commerce platform, which offers products related to Hindu religious practices, including but not limited to photo frames, t-shirts, and other items. The platform also facilitates donations to various charitable causes. By using our application, you agree to comply with and be bound by these Terms & Conditions.</p>

    <h2>2. Definitions</h2>
    <ul>
        <li><strong>"Aghoreshwara," "we," "us," and "our"</strong> refer to the e-commerce platform operated by Jamesons Info Solutions Pvt Ltd.</li>
        <li><strong>"User," "you," and "your"</strong> refer to any individual or entity using the Aghoreshwara platform.</li>
        <li><strong>"Products"</strong> refer to the items available for purchase on the platform, including but not limited to photo frames and t-shirts.</li>
        <li><strong>"Donations"</strong> refer to the charitable contributions facilitated through the platform, including support for old age homes, gau daan (cattle donation), farmer support, orphan support, and charity for Aghoreshwara ashram.</li>
    </ul>

    <h2>3. Use of the Platform</h2>
    <ul>
        <li>By accessing or using Aghoreshwara, you agree to adhere to these Terms & Conditions.</li>
        <li>You must be at least 18 years old to use our platform. If you are under 18, you may use our platform only with the involvement of a parent or guardian.</li>
    </ul>

    <h2>4. Product Information and Availability</h2>
    <ul>
        <li>We strive to provide accurate descriptions and images of our products. However, we do not warrant that the descriptions or images are entirely accurate or complete.</li>
        <li>Product availability may vary, and we reserve the right to limit quantities of products sold or discontinue products at our discretion.</li>
    </ul>

    <h2>5. Ordering and Payment</h2>
    <ul>
        <li>All orders are subject to acceptance by us. We reserve the right to refuse or cancel any order at our discretion.</li>
        <li>Payments are processed through a secure payment gateway. By providing payment information, you authorize us to charge the total amount of your order to your selected payment method.</li>
        <li>Prices and availability of products are subject to change without notice.</li>
    </ul>

    <h2>6. Donations</h2>
    <ul>
        <li>Donations can be made through our platform to support various causes, including old age homes, gau daan, farmer support, orphan support, and Aghoreshwara ashram charity.</li>
        <li>All donations are non-refundable and processed through the same secure payment gateway used for product purchases.</li>
        <li>We do not provide tax receipts for donations; however, we will provide a confirmation of your donation for your records.</li>
    </ul>

    <h2>7. Shipping and Delivery</h2>
    <ul>
        <li>Shipping costs and delivery times will be provided at checkout. We are not responsible for delays caused by factors beyond our control, such as weather conditions or carrier delays.</li>
        <li>Risk of loss or damage to products passes to you upon delivery.</li>
    </ul>

    <h2>8. Returns and Refunds</h2>
    <ul>
        <li>We accept returns for defective or damaged products within 15 days of delivery. Please contact our customer service at <a href="mailto:customerservice@aghoreshwara.org">customerservice@aghoreshwara.org</a> for return instructions.</li>
        <li>Refunds will be processed to the original payment method used for the purchase.</li>
    </ul>

    <h2>9. User Accounts</h2>
    <ul>
        <li>You may be required to create an account to make purchases or donate through our platform. You are responsible for maintaining the confidentiality of your account information and for all activities conducted under your account.</li>
        <li>Notify us immediately of any unauthorized use of your account.</li>
    </ul>

    <h2>10. Intellectual Property</h2>
    <ul>
        <li>All content on Aghoreshwara, including text, graphics, logos, and images, is the property of Aghoreshwara or its licensors and is protected by intellectual property laws.</li>
        <li>You may not use, reproduce, or distribute any content from our platform without our express written permission.</li>
    </ul>

    <h2>11. Limitation of Liability</h2>
    <ul>
        <li>Aghoreshwara is not liable for any indirect, incidental, or consequential damages arising from your use of the platform or any products purchased.</li>
        <li>Our liability is limited to the maximum extent permitted by law.</li>
    </ul>

    <h2>12. Governing Law</h2>
    <p>These Terms & Conditions are governed by and construed in accordance with the laws of Maharashtra. Any disputes arising from these Terms & Conditions or your use of the platform will be resolved in the courts of Maharashtra.</p>

    <h2>13. Changes to Terms</h2>
    <p>We reserve the right to modify these Terms & Conditions at any time. Any changes will be effective when posted on our platform. Your continued use of the platform constitutes acceptance of the revised Terms & Conditions.</p>

    <h2>14. Contact Us</h2>
    <p>If you have any questions or concerns about these Terms & Conditions or our platform, please contact us at <a href="mailto:customerservice@aghoreshwara.org">customerservice@aghoreshwara.org</a> or <a href="mailto:support@aghoreshwara.org">support@aghoreshwara.org</a>.</p>

    </div>
  );
}

export default TremsAndCondition;
