import React from 'react'

function FaildPayment() {
  return (
    <div><div className="failed-container">
    <div className="failed-card">
      <div className="crossmark-container">
        <svg
          className="crossmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle className="crossmark-circle" cx="26" cy="26" r="25" fill="none" />
          <path className="crossmark-cross" fill="none" d="M16 16l20 20M36 16L16 36" />
        </svg>
      </div>
      <h1 className="failed-title">Payment Failed!</h1>
      <p className="failed-message">
        Unfortunately, your transaction could not be completed. Please try again.
      </p>
      <button className="failed-button" onClick={() => window.location.href = '/'}>
        Try Again
      </button>
    </div>
  </div></div>
  )
}

export default FaildPayment