import React from "react";
import Aghoreshwara from "../Home/Aghoreshwara";

function About() {
  return (
    <div>
      <Aghoreshwara />
    </div>
  );
}

export default About;
