// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Layout } from "antd";
import { Image } from "react-bootstrap";
import Cmimg from "../Home/Cmimg";
const { useBreakpoint } = Grid;
function OurWork2() {
  const screens = useBreakpoint();
  const [sliderhide, setsliderhide] = useState(false);
  const navigate = useNavigate();
  console.log(screens);
  console.log(sliderhide);

  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [valuesetUserDeatils, setUserDeatils] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));

      const userDetails = localStorage.getItem("access_user");

      setUserDeatils(JSON?.parse(userDetails));
    } else {
      setValue("");
      setValuevendor("");
      setUserDeatils({});
    }
  }, []);

  useEffect(() => {
    setsliderhide(!screens.sm);
    return () => {};
  }, [screens]);

  const dispatch = useDispatch();
  const get_our_work_list = useSelector((state) =>
    state?.allapi?.get_our_work_list ? state?.allapi?.get_our_work_list : {}
  );

  const ourWorklist = get_our_work_list?.sliders
    ? get_our_work_list?.sliders
    : [];

  useEffect(() => {
    dispatch(allapiAction.getourwork({ page: "home" }));
  }, []);

  const get_home_all_pro = [
    {
      url: "/mix-videos",
      img: "/img/aghori/VIDEO.webp",
      title: "Video",
    },
    {
      url: "/products",
      img: "/img/aghori/PRODUCT.webp",
      title: "Product",
    },
    {
      url: "/all-donation-type",
      img: "/img/aghori/DONATION.webp",
      title: "Donation",
    },
    {
      url: "/service",
      img: "/img/aghori/SERVICE.webp",
      title: "Service",
    },

    {
      url: "/blogs-news",
      img: "/img/aghori/NEWS.webp",
      title: "Blogs & News",
    },
    {
      url: "/about-us",
      img: "/img/aghori/ABOUT-US.webp",
      title: "About Us",
    },
    {
      url: "/p/history",
      img: "/img/aghori/HISTORY.webp",
      title: "Our History",
    },
    {
      url: "/mix-videos",
      img: "/img/aghori/VIDEO.webp",
      title: "Video",
    },
    {
      url: "/products",
      img: "/img/aghori/PRODUCT.webp",
      title: "Product",
    },
    {
      url: "/all-donation-type",
      img: "/img/aghori/DONATION.webp",
      title: "Donation",
    },
    {
      url: "/service",
      img: "/img/aghori/SERVICE.webp",
      title: "Service",
    },

    {
      url: "/blogs-news",
      img: "/img/aghori/NEWS.webp",
      title: "Blogs & News",
    },
    {
      url: "/about-us",
      img: "/img/aghori/ABOUT-US.webp",
      title: "About Us",
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.length == 1
        ? 1
        : get_home_all_pro?.length > 4
          ? 9
          : get_home_all_pro?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 4
                ? 4
                : get_home_all_pro?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 6
                : get_home_all_pro?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container-fluid">
      <div className="mt-1 "></div>

      <div className="text-center mt-5">
        <div className="ps-block__left mb-5"></div>

        {sliderhide ? (
          <div>
            {get_home_all_pro?.length > 0 && (
              <Slider {...settings}>
                {get_home_all_pro?.map((data, i) => {
                  return (
                    <div class="p-5" key={i}>
                      <div className="our_work_card">
                        <Link className="ps-block__overlay wkim" to={data?.url}>
                          {/* <Image
                            src={data?.img}
                            alt={data?.title}
                            loading="lazy"
                          /> */}
                          <Cmimg url={data?.img} altkey={data?.title} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        ) : (
          <div>
            {get_home_all_pro?.length > 0 && (
              <Slider {...settings}>
                {get_home_all_pro?.map((data, i) => {
                  return (
                    <div class="p-5" key={i}>
                      <div className="our_work_card">
                        <Link className="ps-block__overlay wkim" to={data?.url}>
                          {/* <Image
                            src={data?.img}
                            alt={data?.title}
                            loading="lazy"
                          /> */}
                          <Cmimg url={data?.img} altkey={data?.title} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        )}
        {/* {sliderhide ? (
          <div className="row lostBy">
            <div className="col-2">
              <div class="">
                <div className="our_work_card">
                  <Link
                    className="ps-block__overlay wkim"
                    to={"/all-video-short"}
                  >
                    
                    <Cmimg url={"/img/aghori/VIDEO.webp"} altkey={"video"} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div class=" ">
                <div className="our_work_card">
                  <Link className="ps-block__overlay wkim" to={"/products"}>
                    
                    <Cmimg
                      url={"/img/aghori/PRODUCT.webp"}
                      altkey={"product"}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div class=" ">
                <div className="our_work_card">
                  <Link
                    className="ps-block__overlay wkim"
                    to={"/all-donation-type"}
                  >
                     
                    <Cmimg
                      url={"/img/aghori/DONATION.webp"}
                      altkey={"DONATION"}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div class=" ">
                <div className="our_work_card">
                  <Link className="ps-block__overlay wkim" to={"/service"}>
                    
                    <Cmimg
                      url={"/img/aghori/SERVICE.webp"}
                      altkey={"SERVICE"}
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div class=" ">
                <div className="our_work_card">
                  <Link className="ps-block__overlay wkim" to={"/blogs-news"}>
                     
                    <Cmimg url={"/img/aghori/NEWS.webp"} altkey={"NEWS"} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div class=" ">
                <div className="our_work_card">
                  <Link className="ps-block__overlay wkim" to={"/about-us"}>
                    
                    <Cmimg
                      url={"/img/aghori/ABOUT-US.webp"}
                      altkey={"about-us"}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {get_home_all_pro?.length > 0 && (
              <Slider {...settings}>
                {get_home_all_pro?.map((data, i) => {
                  return (
                    <div class="p-5" key={i}>
                      <div className="our_work_card">
                        <Link className="ps-block__overlay wkim" to={data?.url}>
                          
                          <Cmimg url={data?.img} altkey={data?.title} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        )} */}
      </div>
    </div>
  );
}

export default OurWork2;
