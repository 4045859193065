import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";
// import { useRouter } from "next/router";
import React from "react";
import { DeleteToken } from "../../utils";
import { useNavigate } from "react-router-dom";

const TopBar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    DeleteToken();
    // navigate("/login", { replace: true });
    navigate("/login");
  };
  return (
    <div className="flex justify-content-between">
      <div>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          }
        )}

        <span
          className="ml-20 text-white cursor-pointer"
          onClick={() => navigate("/")}
        >
          Home
        </span>
      </div>
      <div className="mr-20">
        <span className="logout-btn" onClick={() => handleLogout()}>
          Logout
          <LogoutOutlined />
        </span>
      </div>
    </div>
  );
};

export default TopBar;
