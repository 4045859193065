// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";
import { allapiAction } from "../../Redux/common/action";
import { Link, useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import { Button, Col, Input, Modal, Row } from "antd";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PinterestShareButton,
  PinterestIcon,
  PocketShareButton,
  PocketIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
} from "react-share";
import TruncateText from "../TruncateText/TruncateText";
function Header() {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [valuesetUserDeatils, setUserDeatils] = useState({});
  const router = useNavigate();

  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );

  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];

  console.log(valuesetUserDeatils);

  const chooseState = (event) => {
    const state = event.target.value;
    dispatch(allapiAction.gethomePro({ state }));
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));

      const userDetails = localStorage.getItem("access_user");

      setUserDeatils(JSON?.parse(userDetails));
    } else {
      setValue("");
      setValuevendor("");
      setUserDeatils({});
    }
  }, []);

  const [shareshow, setstshareshow] = useState(false);
  let pageUrl;

  if (typeof window !== "undefined") {
    if (window.location !== undefined) {
      // Outputs: 'https:'

      // Outputs: '/blog/react-get-current-url/'

      pageUrl = window.location.href;
    }
  }

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Copied!");

    setstshareshow(false);
  }

  const tokenhave = value || valuevendor ? true : false;
  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const get_home_page_list = useSelector((state) =>
    state?.allapi?.get_home_page_list ? state?.allapi?.get_home_page_list : []
  );
  const get_user_profile = useSelector((state) =>
    state?.allapi?.get_user_profile ? state?.allapi?.get_user_profile : {}
  );
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);

  if (typeof window !== "undefined" && Object.keys(window).length > 0) {
    const blogids = localStorage.getItem("cart_product");

    var arr = JSON.parse(blogids && blogids);
    var arrss = tokenhave ? get_all_cart_itme && get_all_cart_itme : arr;
  }

  useEffect(() => {
    dispatch(allapiAction.getCategory({}));
    dispatch(allapiAction.getpageListfront({}));
    return () => {};
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (tokenhave) {
        dispatch(
          allapiAction.getcartitme({
            data: arrss,
          })
        );
      } else {
        if (arrss?.length > 0) {
          dispatch(
            allapiAction.getcartProduct({
              data: arrss,
            })
          );
        }
      }
    }, 500);

    return () => {};
  }, [tokenhave]);

  useEffect(() => {
    if (tokenhave) {
      if (arr?.length > 0) {
        arr.forEach((file) => {
          dispatch(allapiAction.afterLoginAddCart(file));

          var lists = arr.filter((x) => {
            return x.product_id != file?.product_id;
          });

          if (lists?.length == 0) {
            localStorage.removeItem("cart_product");
          } else {
            localStorage.setItem("cart_product", JSON.stringify(lists));
          }

          return () => {};
        });
      }
    }
  }, [get_user_profile]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [searchtype, setsearchtype] = useState("");
  const [searchdata, setsearchdata] = useState("");

  const [userselete, setuserselete] = useState(null);

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaforproductDis?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District + ", " + data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const hendlesearchProduct = (e) => {
    setsearchdata(e?.target?.value);

    if (e.key === "Enter") {
      e.preventDefault();

      if (searchtype == "listing") {
        if (userselete) {
          router(
            `/listings?search=${e?.target?.value}&location=${userselete?._id}`
          );
        } else {
          router(`/listings?search=${e?.target?.value}`);
        }
      } else if (searchtype == "lostandfound") {
        router(`/products?search=${e?.target?.value}`);
      } else {
        if (userselete) {
          router(
            `/products?search=${e?.target?.value}&location=${userselete?._id}`
          );
        } else {
          router(`/products?search=${e?.target?.value}`);
        }
      }
    }
  };

  const hensletosearch = (e) => {
    e.preventDefault();

    if (searchtype == "listing") {
      // router(`/listings?search=${searchdata}`);
      if (userselete) {
        router(`/listings?search=${searchdata}&location=${userselete?._id}`);
      } else {
        router(`/listings?search=${searchdata}`);
      }
    } else if (searchtype == "lostandfound") {
      router(`/products?search=${searchdata}`);
    } else {
      if (userselete) {
        router(`/products?search=${searchdata}&location=${userselete?._id}`);
      } else {
        router(`/products?search=${searchdata}`);
      }
    }
  };

  function removeCartItem(e, color, size) {
    dispatch(
      allapiAction.removeCartItem({
        product_id: e,
        color: color,
        size: size,
      })
    );
  }

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Modal
        title="Share"
        centered
        open={shareshow}
        onOk={() => setstshareshow(false)}
        onCancel={() => setstshareshow(false)}
        width={500}
        footer={null}
      >
        <div>
          <div className="row unishareIcon">
            <div className="col-2 mt-3">
              <FacebookShareButton
                url={pageUrl}
                // quote={blog?.heading}
              >
                <FacebookIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </FacebookShareButton>
            </div>
            <div className="col-2 mt-3">
              <TwitterShareButton url={pageUrl}>
                <TwitterIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TwitterShareButton>
            </div>
            <div className="col-2 mt-3">
              <WhatsappShareButton url={pageUrl}>
                <WhatsappIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </WhatsappShareButton>
            </div>
            <div className="col-2 mt-3">
              <LinkedinShareButton url={pageUrl}>
                <LinkedinIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </LinkedinShareButton>
            </div>
            <div className="col-2 mt-3">
              <EmailShareButton url={pageUrl}>
                <EmailIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </EmailShareButton>
            </div>
            <div className="col-2 mt-3">
              <MailruShareButton url={pageUrl}>
                <MailruIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </MailruShareButton>
            </div>
            <div className="col-2 mt-3">
              <OKShareButton url={pageUrl}>
                <OKIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </OKShareButton>
            </div>
            <div className="col-2 mt-3">
              <PinterestShareButton url={pageUrl}>
                <PinterestIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </PinterestShareButton>
            </div>
            <div className="col-2 mt-3">
              <PocketShareButton url={pageUrl}>
                <PocketIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </PocketShareButton>
            </div>
            <div className="col-2 mt-3">
              <RedditShareButton url={pageUrl}>
                <RedditIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </RedditShareButton>
            </div>
            <div className="col-2 mt-3">
              <TelegramShareButton url={pageUrl}>
                <TelegramIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TelegramShareButton>
            </div>
            <div className="col-2 mt-3">
              <TumblrShareButton url={pageUrl}>
                <TumblrIcon
                  size={26}
                  round={true}
                  onClick={() => setstshareshow(false)}
                />
              </TumblrShareButton>
            </div>
          </div>

          <div className="shareLinkInModel">
            {" "}
            <p>
              <b>{pageUrl}</b>
            </p>
          </div>
          <div className="shareLinkInModel">
            <Button
              variant="primary"
              onClick={() => {
                copy();
                // onCloseModal();
              }}
            >
              {!copied ? "Copy link" : "Copied!"}
            </Button>
          </div>
        </div>
      </Modal>
      <header
        className={
          isSticky ? "header header--1 header--sticky" : "header header--1"
        }
        data-sticky="true"
      >
        <div className="header__top">
          <div className="ps-container">
            <div className="header__left">
              <div className="menu--product-categories px-3">
                <div className="menu__toggle">
                  <i className="icon-menu"></i>
                  <span> Shop Here</span>
                </div>
                <div className="menu__content">
                  <ul className="menu--dropdown">
                    {all_categories_List &&
                      all_categories_List?.map((data, i) => {
                        return (
                          <li
                            key={i}
                            className={
                              data?.subcates?.length > 0
                                ? "menu-item-has-children has-mega-menu"
                                : ""
                            }
                          >
                            <a href="#">{data?.name}</a>
                            <span className="sub-toggle"></span>
                            {data?.subcates?.length > 0 && (
                              <div className="mega-menu">
                                {data?.subcates &&
                                  data?.subcates?.map((datasub, index) => {
                                    return (
                                      <div
                                        className="mega-menu__column"
                                        key={index}
                                      >
                                        <h4>
                                          {datasub?.name}
                                          <span className="sub-toggle"></span>
                                        </h4>
                                        <ul className="mega-menu__list">
                                          {datasub?.subjectss &&
                                            datasub?.subjectss?.map(
                                              (datasubsub, indexss) => {
                                                return (
                                                  <li key={indexss}>
                                                    <Link
                                                      to={
                                                        "/category-product/" +
                                                        datasubsub?.cate_sulg
                                                      }
                                                      onClick={() => {
                                                        router(
                                                          "/category-product/" +
                                                            datasubsub?.cate_sulg
                                                        );
                                                      }}
                                                    >
                                                      {datasubsub?.name}
                                                    </Link>
                                                  </li>
                                                );
                                              }
                                            )}
                                        </ul>
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <Link className="ps-logo" to="/">
                <img
                  src="/img/aghori/Aghoreshwaralogo.webp"
                  className="homeLogoicon"
                  alt=""
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="header__center">
              {/* <AsyncPaginate
                value={userselete}
                className="liceninput"
                loadOptions={loadOptionsPalyer}
                onChange={setuserselete}
                additional={{
                  page: 1,
                }}
              /> */}
              {/* <div className="form-group--icon mb-5">
                <AsyncPaginate
                  value={userselete}
                  className="liceninput"
                  // placeholder="location"
                  placeholder="Location"
                  loadOptions={loadOptionsPalyer}
                  onChange={setuserselete}
                  additional={{
                    page: 1,
                  }}
                />
              </div> */}
              {/* <div className="form-group--icon mb-5">
                <i className="icon-chevron-down"></i>
                <select
                  onChange={chooseState}
                  className="form-control form-control2 serachtypebox"
                >
                  <option value={""}>Select State</option>
                  {statesindia &&
                    statesindia?.map((data, i) => {
                      return (
                        <option key={i} value={data?.name}>
                          {data?.name}
                        </option>
                      );
                    })}
                </select>
              </div> */}
              <form className="ps-form--quick-search" method="get">
                {/* <div className="form-group--icon">
                <AsyncPaginate
                value={userselete}
                className="liceninput"
                loadOptions={loadOptionsPalyer}
                onChange={setuserselete}
                additional={{
                  page: 1,
                }}
              />
                </div> */}
                {/* <div className="form-group--icon">
                  <i className="icon-chevron-down"></i>
                  <select
                    className="form-control serachtypebox"
                    onClick={(e) => {
                      setsearchtype(e?.target?.value);
                    }}
                  >
                    <option value="">Product</option>
                    <option className="level-0" value="listing">
                      Listing
                    </option>
                  </select>
                </div> */}
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search Product  "
                  onChange={(e) => {
                    hendlesearchProduct(e);
                  }}
                  onKeyDown={(e) => {
                    hendlesearchProduct(e);
                  }}
                  id="input-search"
                />
                <button
                  onClick={(e) => {
                    hensletosearch(e);
                  }}
                  type="button"
                >
                  Search
                </button>
                <div className="ps-panel--search-result">
                  <div className="ps-panel__content"></div>
                  <div className="ps-panel__footer text-center">
                    <a href="shop-default.html">See all results</a>
                  </div>
                </div>
              </form>
            </div>
            <div className="header__right">
              <div className="header__actions">
                <div className="ps-block--user-header">
                  <div className="ps-block__right">
                    {value || valuevendor ? "" : <Link to="/login">Login</Link>}
                    {value || valuevendor ? (
                      ""
                    ) : (
                      <Link to="/register">Register</Link>
                    )}
                    {value ? (
                      <Link to="/accounts" style={{ color: "#ffffff" }}>
                        {" "}
                        Namaskar {valuesetUserDeatils?.fullName}
                      </Link>
                    ) : (
                      ""
                    )}
                    {valuevendor ? (
                      <Link to="/seller" style={{ color: "#ffffff" }}>
                        {" "}
                        Namaskar {valuesetUserDeatils?.fullName}
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="ps-cart--mini">
                  <a className="header__extra" href="#">
                    <i className="fa fa-cart-arrow-down icon-bag2"></i>
                    <span>
                      <i>
                        {get_all_cart_itme?.length > 0
                          ? get_all_cart_itme?.length
                          : 0}
                      </i>
                    </span>
                  </a>
                  <div className="ps-cart__content">
                    <div className="ps-cart__items">
                      {get_all_cart_itme &&
                        get_all_cart_itme?.map((data, i) => {
                          return (
                            <div className="ps-product--cart-mobile" key={i}>
                              <div className="ps-product__thumbnail">
                                <Link to={"/product/" + data?.pro_sulg}>
                                  <img
                                    src={URL?.API_BASE_URL + data?.featureImage}
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="ps-product__content">
                                <Link to={"/product/" + data?.pro_sulg}>
                                  {data?.title}
                                </Link>
                                <p></p>
                                <small>
                                  {data?.item} x ₹{data?.afterdiscountprice}
                                </small>
                                &nbsp;&nbsp;
                                <button
                                  className="btn btn-danger btn-sm"
                                  type="button"
                                  onClick={() =>
                                    removeCartItem(
                                      data?.productId?._id,
                                      data?.productcolorId?._id,
                                      data?._id
                                    )
                                  }
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="ps-cart__footer">
                      <h3>
                        Sub Total:
                        <strong>
                          ₹{totalSubtotalPrice > 0 ? totalSubtotalPrice : 0}
                        </strong>
                      </h3>
                      <figure>
                        {tokenhave ? (
                          <Link
                            className="ps-btn"
                            to={get_all_cart_itme.length ? "/cart" : "#"}
                          >
                            View Cart
                          </Link>
                        ) : (
                          <button
                            className="ps-btn"
                            onClick={() => {
                              router("/login");
                              toast.error("You need to login first!");
                            }}
                          >
                            View Cart
                          </button>
                        )}
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navigation">
          <div className="ps-container">
            <div className="navigation__left">
              <div className="menu--product-categories">
                <div className="menu__toggle">
                  <i className="icon-menu"></i>
                  <span> Shop Here</span>
                </div>
                <div className="menu__content">
                  <ul className="menu--dropdown">
                    {all_categories_List &&
                      all_categories_List?.map((data, i) => {
                        return (
                          <li
                            key={i}
                            className={
                              data?.subcates?.length > 0
                                ? "menu-item-has-children has-mega-menu"
                                : ""
                            }
                          >
                            <Link to={"/category-products/" + data?.slug}>
                              {data?.name}
                            </Link>
                            <span className="sub-toggle"></span>
                            {data?.subcates?.length > 0 && (
                              <div className="mega-menu">
                                {data?.subcates &&
                                  data?.subcates?.map((datasub, index) => {
                                    return (
                                      <div
                                        className="mega-menu__column"
                                        key={index}
                                      >
                                        <h4>
                                          {datasub?.name}
                                          <span className="sub-toggle"></span>
                                        </h4>
                                        <ul className="mega-menu__list">
                                          {datasub?.subjectss &&
                                            datasub?.subjectss?.map(
                                              (datasubsub, indexss) => {
                                                return (
                                                  <li key={indexss}>
                                                    <Link
                                                      to={
                                                        "/category-product/" +
                                                        datasubsub?.cate_sulg
                                                      }
                                                      onClick={() => {
                                                        router(
                                                          "/category-product/" +
                                                            datasubsub?.cate_sulg
                                                        );
                                                      }}
                                                    >
                                                      {datasubsub?.name}
                                                    </Link>
                                                  </li>
                                                );
                                              }
                                            )}
                                        </ul>
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="navigation__right">
              <ul className="menu">
                <li className="menu-item-has-children">
                  <Link to="/">Home</Link>
                </li>
                <li className="menu-item-has-children">
                  <div className="navigation__left">
                    <div className="menu--product-categories">
                      <div className="menu__toggle">
                        <p style={{color:"navy"}}> About Us</p>
                      </div>
                      <div className="menu__content bg-white headerboxnew">
                        <ul>
                          <li>
                            {" "}
                            <Link to={"/branch-list"}>Branches</Link>
                          </li>
                          {get_home_page_list &&
                            get_home_page_list?.map((data, i) => {
                              return (
                                <li>
                                  {" "}
                                  <Link to={"/p/" + data?.page_sulg}>
                                    {data?.title}
                                  </Link>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/products">Product</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/service">Serivce</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/blogs-news">News</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/mix-videos">Video</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/all-donation-type">Donation</Link>
                </li>

                {/* <li className="menu-item-has-children">
                  <Link to="/conservatism">Conservatism</Link>
                </li> */}
                {/* <li className="menu-item-has-children">
                  <Link to="/student">Student</Link>
                </li> */}
                {/* {(value || valuevendor) && ( */}
                {/* <li className="menu-item-has-children">
                  <Link
                    to={value || valuevendor ? "/add-lost-found" : "/login"}
                  >
                    Add Lost And Found
                  </Link>
                </li> */}
                {/* )} */}
                {/* {(value || valuevendor) && ( */}
                {/* <li className="menu-item-has-children">
                  <Link to={value || valuevendor ? "/message" : "/login"}>
                    Add Message
                  </Link>
                </li> */}
                {/* )} */}
                {/* {value || valuevendor ? (
                  <></>
                ) : (
                  <li className="menu-item-has-children">
                    <Link to="/vendor-login">Become A Vendor</Link>
                  </li>
                )} */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <header
        className={
          isSticky
            ? "header header--mobile header--sticky"
            : "header header--mobile"
        }
        data-sticky="true"
      >
        <div className="navigation--mobile">
          <div className="navigation__left">
            <Link className="ps-logo" to="/">
              {/* <span className="gradient-color-text">BestBharat</span> */}

              <img
                src="/img/aghori/Aghoreshwaralogo.webp"
                className="homeLogoicon1"
                alt=""
                loading="lazy"
              />
              {/* <img
                src="/img/message/sudarshanchakra.gif"
                className="homeLogoicon1"
                alt=""
              /> */}
            </Link>
          </div>
          <div className="navigation__right">
            <div className="header__actions">
              <div className="ps-block--user-header">
                <div className="ps-block__left ">
                  {value && (
                    <Link to="/accounts" style={{ color: "#ffffff" }}>
                      Namaskar{" "}
                      <TruncateText
                        text={valuesetUserDeatils?.fullName}
                        maxLength={windowWidth > 600 ? 25 : 10}
                      />
                    </Link>
                  )}
                  {valuevendor && (
                    <Link
                      to="/seller"
                      style={{ marginLeft: "5px", color: "#ffffff" }}
                    >
                      Namaskar{" "}
                      <TruncateText
                        text={valuesetUserDeatils?.fullName}
                        maxLength={windowWidth > 600 ? 25 : 10}
                      />
                    </Link>
                  )}

                  {!valuevendor && !value && (
                    <Link to="/login" className="lgn lgn1">
                      {" "}
                      Login{" "}
                    </Link>
                  )}
                  {!valuevendor && !value && (
                    <Link className="lgn lgn2" to="/register">
                      Register
                    </Link>
                  )}
                </div>
              </div>
              <div className="ps-cart--mini">
                <a className="header__extra" href="#">
                  <i className="fa fa-cart-arrow-down icon-bag2"></i>
                  <span>
                    <i>
                      {" "}
                      {get_all_cart_itme?.length > 0
                        ? get_all_cart_itme?.length
                        : 0}
                    </i>
                  </span>
                </a>
                <div className="ps-cart__content">
                  <div className="ps-cart__items">
                    {get_all_cart_itme &&
                      get_all_cart_itme?.map((data, i) => {
                        return (
                          <div className="ps-product--cart-mobile" key={i}>
                            <div className="ps-product__thumbnail">
                              <Link to={"/product/" + data?.pro_sulg}>
                                <img
                                  src={URL?.API_BASE_URL + data?.featureImage}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="ps-product__content">
                              <Link to={"/product/" + data?.pro_sulg}>
                                {data?.title}
                              </Link>
                              <p></p>
                              <small>
                                {data?.item} x ₹{data?.afterdiscountprice}
                              </small>
                              &nbsp;&nbsp;
                              <button
                                className="btn btn-danger btn-sm"
                                type="button"
                                onClick={() =>
                                  removeCartItem(
                                    data?.productId?._id,
                                    data?.productcolorId?._id,
                                    data?._id
                                  )
                                }
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="ps-cart__footer">
                    <h3>
                      Sub Total: ₹
                      {totalSubtotalPrice > 0 ? totalSubtotalPrice : 0}
                    </h3>
                    <figure>
                      {tokenhave ? (
                        <Link
                          className="ps-btn"
                          to={get_all_cart_itme.length ? "/cart" : "#"}
                        >
                          View Cart
                        </Link>
                      ) : (
                        <button
                          className="ps-btn"
                          onClick={() => {
                            router("/login");
                            toast.error("You need to login first!");
                          }}
                        >
                          View Cart
                        </button>
                      )}
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ps-search--mobile">
          {/* <div className="container-fluid"> */}
          <div className="">
            {/* <div className="form-group--icon mb-5">
              <i className="icon-chevron-down"></i>
              <select
                onChange={chooseState}
                className="form-control form-control2 serachtypebox"
              >
                <option value={""}>Select State</option>
                {statesindia &&
                  statesindia?.map((data, i) => {
                    return (
                      <option key={i} value={data.name}>
                        {data.name}
                      </option>
                    );
                  })}
              </select>
            </div> */}

            {/* <div className="marquee relative hdmq">
            <marquee behavior="scroll" direction="left" scrollamount="05">
              {about &&
                about?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div> */}
            <form className="ps-form--search-mobile" method="get">
              <div className="form-group--nest gap5">
                {/* <div className="form-group--icon mb-5">
                  {" "}
                  <AsyncPaginate
                    value={userselete}
                    className="liceninput"
                    loadOptions={loadOptionsPalyer}
                    onChange={setuserselete}
                    additional={{
                      page: 1,
                    }}
                  />
                </div> */}
                {/* <div className="form-group--icon mb-5">
                  <i className="icon-chevron-down"></i>
                  <select
                    onChange={chooseState}
                    className="form-control form-control2 serachtypebox"
                  >
                    <option value={""}>State</option>
                    {statesindia &&
                      statesindia?.map((data, i) => {
                        return (
                          <option key={i} value={data.name}>
                            {data.name}
                          </option>
                        );
                      })}
                  </select>
                </div> */}
                {/* <div className="form-group--icon area">
                  <AsyncPaginate
                    value={userselete}
                    className="liceninputvs"
                    placeholder="Location"
                    loadOptions={loadOptionsPalyer}
                    onChange={setuserselete}
                    additional={{
                      page: 1,
                    }}
                  />
                </div> */}

                <input
                  className="form-control"
                  type="text"
                  placeholder="Search Product "
                  onChange={(e) => {
                    hendlesearchProduct(e);
                  }}
                  onKeyDown={(e) => {
                    hendlesearchProduct(e);
                  }}
                />
                <button
                  onClick={(e) => {
                    hensletosearch(e);
                  }}
                  className="btn btn-sm mt-5"
                  type="button"
                >
                  <i className="icon-magnifier"></i>
                </button>
                <button
                  // onClick={(e) => {
                  //   hensletosearch(e);
                  // }}
                  onClick={() => {
                    setstshareshow(true);
                  }}
                  className="btn btn-sm mt-5"
                  type="button"
                >
                  <i className="icon-share2"></i>
                </button>
                {/* <button
                  // onClick={(e) => {
                  //   hensletosearch(e);
                  // }}
                  className="btn btn-sm mt-5"
                  type="button"
                >
                  <a
                    href="/img/aghroshwara.apk"
                    target="_blank"
                    download={true}
                  >
                    
                    <img src="/img/apk.png" width={20} loading="lazy" />
                  </a>
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
