import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
import Slider from "react-slick";
import Boxes3 from "./Boxes3";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { allapiAction } from "../../../Redux/common/action";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../Redux/common/url";
import ImageCropper from "../../ImageCroper/ImageCropper";

function Deshboard1() {
  const dispatch = useDispatch();
  const [profileModal, setProfileModal] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  const get_category_sale = useSelector((state) =>
    state?.allapi?.get_category_sale ? state?.allapi?.get_category_sale : []
  );

  const get_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );


  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );

  console.log(deshboard);
  
  const profileModalShow = () => setProfileModal(true);
  const profileModalHide = () => setProfileModal(false);

  const router = useNavigate();

  const handleProfileData = (e) => {
    const { name, value } = e.target;
    setUserProfile({
      ...userProfile,
      [name]: value,
    });
  };

  async function submitProfileData() {
    if (!userProfile.fullName) {
      toast.error("Please enter full name!");
    } else if (!userProfile.email) {
      toast.error("Please enter email!");
    } else {
      await dispatch(allapiAction.updateUserProfile(userProfile));
      dispatch(allapiAction.getprofile());
      setProfileModal(false);
    }
  }

  useEffect(() => {
    // dispatch(allapiAction.vendordeshBoradData({}));
    // dispatch(allapiAction.categoryWiseSale({}));
    // dispatch(allapiAction.getprofile());
    getProfileData();

    return () => {};
  }, []);
  const getProfileData = async () => {
    const response = await dispatch(allapiAction.getprofile());
    const response2 = await dispatch(allapiAction.userDashBoradData());
    if (response?.success) {
      setUserProfile(response?.data);
    }
  };
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const data2 = [
    ["Year", "Sales", "Expenses"],
    ["Monday", 20000, 30000],
    ["Tuesday", 35000, 15000],
    ["Wednesday", 38000, 14000],
    ["Thursday", 25000, 35000],
    ["Friday", 34000, 22000],
    ["Saturday", 42000, 34000],
    ["Sunday", 18000, 38000],
  ];
  const data = get_category_sale;

  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  let pageUrl =
    "https://bestbharat.com/referalsignup?ref=" + get_profile?.referId;

  function copy() {
    const el = document.createElement("input");
    el.value = pageUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // setCopied(true);
    toast.success("Your Referal link Copied!");

    // setstshareshow(false);
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [fileName2, setFileName2] = useState("");
  const [fileContant2, setfileContant2] = useState("");
  const [finalImg2, setfinalImg2] = useState("");
  const [croppedImageUrl2, setCroppedImageUrl2] = useState(null);
  const submitProduct2 = () => {
    const data = new FormData();
    // if (images[0]?.file) {
    //   // roledata?.role_name
    //   // data.append("logo", images[0]?.file);
    //   data.append("logo", fileContant, fileName);
    //   dispatch(allapiAction.updateBussLogovendor(data));
    // }
    data.append("logo", fileContant2, fileName2);
    dispatch(allapiAction.updateBussuserProfile(data));
    setfinalImg2("");
    setShow(false);
    setCroppedImageUrl2(null);
  };

  return (
    <div>
      <Row justify={"center"}>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <Row gutter={[0, 20]}>
              {/* <div class="buttons">
    <button class="btn-hover color-1">BUTTON</button>
    <button class="btn-hover color-2">BUTTON</button>
    <button class="btn-hover color-3">BUTTON</button>
    <button class="btn-hover color-4">BUTTON</button>
    <button class="btn-hover color-5">BUTTON</button>
    <button class="btn-hover color-6">BUTTON</button>
    <button class="btn-hover color-7">BUTTON</button>
    <button class="btn-hover color-8">BUTTON</button>
    <button class="btn-hover color-9">BUTTON</button>
    <button class="btn-hover color-10">BUTTON</button>
    <button class="btn-hover color-11">BUTTON</button>
</div> */}
              <Col lg={24} md={24} sm={24} xs={24}>
                <div className="flex1 p-10 weather_section  b2s m-5 cursor-pointer">
                  <div className="row justify-content-between align-item-center">
                    <div className="col-3 col-md-3 overflow-hidden">
                      <div>
                        <label>Profile</label>
                        <div style={{ width: "220px" }}>
                          <div>
                            <ImageCropper
                              aspectRatio={4 / 3}
                              // onImageCrop={handleImageCrop}
                              setfileContant={setfileContant2}
                              setFileName={setFileName2}
                              setfinalImg={setfinalImg2}
                              aspectwidth={16}
                              aspectheight={16}
                              onCropComplete={setCroppedImageUrl2}
                            />
                            {croppedImageUrl2 && (
                              <div>
                                <img
                                  src={croppedImageUrl2}
                                  alt="Cropped"
                                  width={130}
                                  height={130}
                                />
                                <div>
                                  <Button
                                    variant="primary"
                                    onClick={() => {
                                      submitProduct2();
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <hr />
                        {get_profile?.profile_image ? (
                          <img
                            src={URL.API_BASE_URL + get_profile?.profile_image}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <img
                            src="/img/message/sudarshanchakra.gif"
                            style={{ width: "100%" }}
                            alt="profile"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-9 col-md-9">
                      {/* <div className="relative boxHeadingTotal">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <div class="buttons">
                              <button
                                class="btn-hover color-2"
                                onClick={() => {
                                  router("/accounts/refferal");
                                }}
                              >
                                Join Refferal Program
                              </button>
                            </div>
                          </div>
                          <div class="col-12 col-md-6">
                            <div class="buttons">
                              <button
                                class="btn-hover color-2"
                                onClick={() => {
                                  copy();
                                }}
                              >
                                Refer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Uid : {get_profile?.referId}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Name : {get_profile?.fullName}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Email : {get_profile?.email}
                        </div>
                      </div>
                      <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Phone : {get_profile?.phone}
                        </div>
                      </div>
                      {/* <div className="relative boxHeadingTotal">
                        <div className="fw-600">
                          Referral Program : {get_profile?.isrefferal}
                        </div>
                      </div> */}
                      <Button
                        type="primary"
                        title="Update"
                        onClick={profileModalShow}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div className=" p-10">
            {/* <div className="hometest">
              <Slider {...settings}>
                <div>
                  <a className="ps-banner__overlay" href="#">
                    <img src="/img/slider1.jpg" alt="" />
                  </a>
                </div>
                <div className="ps-banner bg--cover">
                  <a className="ps-banner__overlay" href="#">
                    <img src="/img/slider2.jpg" alt="" />
                  </a>
                </div>
                <div className="ps-banner bg--cover">
                  <a className="ps-banner__overlay" href="#">
                    <img src="/img/slider3.jpg" alt="" />
                  </a>
                </div>
                <div className="ps-banner bg--cover">
                  <a className="ps-banner__overlay" href="#">
                    <img src="/img/slider4.jpg" alt="" />
                  </a>
                </div>
              </Slider>
            </div> */}
          </div>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className=" p-10">
            <Boxes3 />
          </div>
        </Col>
      </Row>

      <Modal show={profileModal} onHide={profileModalHide} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card mx-auto">
            <div className="card-body">
              <div className="mb-3">
                <label className="form-label">Full Name</label>
                <input
                  name="fullName"
                  className="form-control"
                  placeholder="Enter full Name"
                  type="text"
                  value={userProfile.fullName}
                  onChange={handleProfileData}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  type="text"
                  value={userProfile.email}
                  onChange={handleProfileData}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={profileModalHide}>
            Cancel
          </Button>
          <Button variant="primary" onClick={submitProfileData}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Deshboard1;
