import React from "react";

function ReturnandRefundPolicy() {
  return (
    <div className="container">
      <div className="mt-20"></div>
      <h1>Refund and Donation Policy</h1>

      <p>
        At Aghoreshwara, we are committed to providing products and services
        that honor and support Hindu religious practices. Our offerings include
        items such as photo frames, t-shirts, and other products related to
        Hindu religion, as well as a donation feature to support various causes
        like old age homes, gau daan (cow donations), farmer support, orphan
        support, and charity to Aghoreshwar ashrams.
      </p>

      <h2>Product Refunds:</h2>
      <ul>
        <li>
          <strong>Eligibility for Refunds:</strong>
          <ul>
            <li>
              Refunds are only applicable to physical products (e.g., photo
              frames, t-shirts, etc.) purchased through the Aghoreshwara
              application.
            </li>
            <li>
              Refunds are eligible for damaged, defective, or incorrect products
              received.
            </li>
            <li>
              To qualify for a refund, customers must notify us within 14 days
              of receiving the product, providing clear evidence (e.g., photos
              or videos) of the issue.
            </li>
          </ul>
        </li>

        <li>
          <strong>Non-Refundable Items:</strong>
          <ul>
            <li>
              Products that are personalized or custom-made based on customer
              requests are non-refundable unless they are damaged or defective
              upon arrival.
            </li>
            <li>Used or washed items will not be eligible for a refund.</li>
          </ul>
        </li>

        <li>
          <strong>Refund Process:</strong>
          <ul>
            <li>
              Once a refund request is approved, the refund will be processed to
              the original payment method within 7-10 business days.
            </li>
            <li>
              Customers may need to return the item in its original packaging
              (if applicable). Return shipping costs may be applicable based on
              the situation.
            </li>
          </ul>
        </li>

        <li>
          <strong>Cancellation of Orders:</strong>
          <ul>
            <li>
              Orders can be canceled before they are dispatched. Once
              dispatched, the order cannot be canceled but may be eligible for a
              return and refund under the conditions stated above.
            </li>
          </ul>
        </li>
      </ul>

      <h2>Donation Refunds:</h2>
      <ul>
        <li>
          <strong>Donation Refunds:</strong>
          <ul>
            <li>
              Donations made through the Aghoreshwara application (for old age
              homes, gau daan, farmer support, orphan support, or Aghoreshwar
              ashram charity) are non-refundable.
            </li>
            <li>
              Once the donation has been processed, it is considered final and
              cannot be refunded or canceled.
            </li>
          </ul>
        </li>

        <li>
          <strong>Discrepancies in Donation Amount:</strong>
          <ul>
            <li>
              In the case of an incorrect donation amount being debited due to a
              technical issue, please contact our customer support team within
              24 hours, and we will review the request.
            </li>
          </ul>
        </li>
      </ul>

      <h2>Payment Disputes:</h2>
      <p>
        For any disputes related to payments or transactions made through our
        payment gateway, kindly reach out to our customer support team with the
        necessary details, and we will work to resolve the issue promptly.
      </p>

      <h2>Contact Us:</h2>
      <p>
        For any further inquiries or support regarding refunds or donations,
        please contact our customer service team at{" "}
        <a href="mailto:customerservice@aghoreshwara.org">
          customerservice@aghoreshwara.org
        </a>
        .
      </p>
    </div>
  );
}

export default ReturnandRefundPolicy;
