import React, { useEffect, useState } from "react";
import { allapiAction } from "../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";

function JobViewDetails2() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  // getjobDetailst
  const details = useSelector((state) =>
    state?.allapi?.grt_jobs_details ? state?.allapi?.grt_jobs_details : {}
  );
  const grt_jobs_details_releted = useSelector((state) =>
    state?.allapi?.grt_jobs_details_releted
      ? state?.allapi?.grt_jobs_details_releted
      : []
  );
  console.log(details);
  console.log(grt_jobs_details_releted);

  const [tokenuser, settokenuser] = useState("");
  const [tokenvendor, settokenverndorr] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const tokenvn = localStorage.getItem("access_token_vendor");
    settokenuser(token);
    settokenverndorr(tokenvn);
  }, []);
  useEffect(() => {
    dispatch(allapiAction.getjobDetailst({ slug: id }));
    dispatch(allapiAction.getjobreleted({ slug: id }));
  }, []);
  return (
    <div>
      <div class="monitoe bgnew">
        <div class="container-fluid mt-5">
          <div class="row">
            <div class="col-1"></div>

            <div class="col-md-6">
              <div className="lost_fount_form">
                <div class="row m-1">
                  <div class="col-12 border">
                    {details?.hideSalary == "0" && (
                      <div class="row mt-2">
                        <div class="col-12 ml-1 ">
                          <i class="fa fa-rupee">
                            &nbsp;&nbsp;₹{details?.salary_from} - ₹
                            {details?.salary_to} monthly*
                          </i>
                        </div>
                      </div>
                    )}

                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="row">
                          <div class="col-sm-2">
                            <button class="btn btn-primary m-1">
                              <i
                                class="fa fa-hospital-o"
                                style={{ fontSize: "30px" }}
                              ></i>
                            </button>
                          </div>
                          <div class="col-sm-10">
                            <p>
                              <b>{details?.job_title}</b>
                              <br />
                              {details?.company_name}
                            </p>
                          </div>

                          <div class="col-12 ml-1 ">
                            <img
                              src="/img/jobicon/location.png"
                              alt=""
                              width={20}
                            />
                            &nbsp;&nbsp;{details?.city}
                          </div>
                        </div>

                        <p type="button" class=" ">
                          <img
                            src="/img/jobicon/office-building.png"
                            alt=""
                            width={20}
                          />
                          &nbsp;&nbsp;{details?.workinglocationtype}
                        </p>
                       
                      </div>
                      <div class="col-6">
                        <div class="row mt-2 mb-25">
                          <div class="col-sm-9 mt-1">
                            <button
                              type="button"
                              onClick={() => {
                                if (tokenuser) {
                                  navigate("/job-apply/" + id);
                                } else {
                                  navigate("/login");
                                }
                              }}
                              class="btn btn-success btn-lg btn-block fa fa-"
                            >
                              Apply Now
                            </button>
                          </div>
                          {/* <div class="col-sm-3 mt-1">
                            <button
                              type="button"
                              class="btn btn-secondary btn-lg btn-block fa fa-share"
                            >
                              &nbsp;share
                            </button>
                          </div> */}
                        </div>
                        <p type="button" class=" ">
                          <img
                            src="/img/jobicon/letter-f.png"
                            alt=""
                            width={20}
                          />
                          &nbsp;&nbsp;{details?.job_shift}
                        </p>
                        <p type="button" class=" ">
                          <img
                            src="/img/jobicon/portfolio.png"
                            alt=""
                            width={20}
                          />
                          &nbsp;&nbsp;{details?.job_experience}
                        </p>
                        <p type="button" class=" ">
                          <img src="/img/jobicon/eng.png" alt="" width={20}     loading="lazy"/>
                          &nbsp;&nbsp;{details?.language}
                        </p>
                      </div>
                    </div>

                    <br />
                  </div>
                </div>
              </div>

              <div className="lost_fount_form p-2 mt-35 mb-35">
                <h4>
                  {" "}
                  &nbsp;&nbsp;<b>Job role</b>
                </h4>
                <div className="container">
                  <div className="row  ">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Work location</b>
                        </div>
                        <div className="col-6 jbboder">
                          {details?.workinglocationtype}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Employment type</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.job_type}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Role / Category</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.job_category}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Shift</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.job_shift}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>
                  {" "}
                  &nbsp;&nbsp;<b>Job requirements</b>
                </h4>

                <div className="container">
                  <div className="row  ">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Experience</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.job_experience}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Skills</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.anyskills}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Gender</b>
                        </div>
                        <div className="col-6 jbboder"> {details?.gender}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Education</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.degree_level}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Language</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.language}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>
                  {" "}
                  &nbsp;&nbsp;<b>About Company</b>
                </h4>
                <div className="container">
                  <div className="row  ">
                    <div className="col-12 col-md-12">
                      <div className="row">
                        <div className="col-3 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Name</b>
                        </div>
                        <div className="col-9 jbboder">
                          {" "}
                          {details?.company_name}
                        </div>
                        <div className="col-3 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Address</b>
                        </div>
                        <div className="col-9 jbboder">
                          {" "}
                          {details?.address}, {details?.city}, {details?.state}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>
                  {" "}
                  &nbsp;&nbsp;<b>Job Description</b>
                </h4>
                <div className="container">
                  <div className="row  ">
                    <div
                      class="col-12 jbboder"
                      dangerouslySetInnerHTML={{
                        __html: details?.job_discription,
                      }}
                    ></div>
                  </div>
                </div>
                {/* <hr /> */}
              </div>
              
            </div>

            <div class="col-md-4">
              <div class="row m-1">
                <div class="col-12 border">
                  <p class="mt-2">
                    <b>Similar jobs</b>
                  </p>
                  {grt_jobs_details_releted &&
                    grt_jobs_details_releted.map((data) => {
                      return (
                        <div class="similorJobBox">
                          <div class="row">
                            <div class="col-2">
                             
                              <i
                                class="fa fa-hospital-o"
                                style={{ fontSize: "30px" }}
                              ></i>
                            </div>
                            <div class="col-8">
                              <p>
                                <b>{data?.job_title}</b>
                                <br />
                                {data?.job_category}
                              </p>
                            </div>
                            <div class="col-2">
                              
                            </div>
                          </div>
                          <img
                            src="/img/jobicon/location.png"
                            alt=""
                            width={20}
                          />
                         
                          &nbsp;&nbsp;{details?.city}
                          <br />
                          {!data?.hideSalary && (
                            <i class="fa fa-rupee ml-1">
                              &nbsp;&nbsp;₹{data?.salary_from} - ₹
                              {data?.salary_to} monthly*
                            </i>
                          )}
                          <br />
                          <div class="row">
                            <div class="col-12 mt-2">
                              <button
                                type="button"
                                class="btn p-0 mx-2 btn-light"
                              >
                                <img
                                  src="/img/jobicon/portfolio.png"
                                  alt=""
                                  width={20}
                                />
                               
                                &nbsp;&nbsp;{data?.job_experience}
                              </button>
                              <button
                                type="button"
                                class="btn p-0 mx-2 btn-light"
                              >
                                <img
                                  src="/img/jobicon/letter-f.png"
                                  alt=""
                                  width={20}
                                />
                                
                                &nbsp;&nbsp;{data?.job_shift}
                              </button>
                              <button
                                type="button"
                                class="btn p-0 mx-2 btn-light"
                              >
                                <img
                                  src="/img/jobicon/office-building.png"
                                  alt=""
                                  width={20}
                                />{" "}
                                &nbsp;&nbsp;{data?.job_shift}
                              </button>
                            </div>
                          </div>
                    
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div class="col-2"></div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default JobViewDetails2;
