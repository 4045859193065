import React from "react";
import {
  DashboardOutlined,
  FileOutlined,
  ProjectOutlined,
  GroupOutlined,
  ExportOutlined,
  WeiboSquareFilled,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";
import { ShowMenu } from "../../utils";
import { useNavigate } from "react-router-dom";
// import { useRouter } from "next/router";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const { openKey, selectKey } = useBasePath();
  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      style={{ display: collapsed ? "none" : "block" }}
      width={collapsed ? 0 : 200}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#089dc3" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard Management",
              style: { display: ShowMenu(["Dashboard Management"]) },
              onClick: () => {
                navigate("/seller");
              },
            },
            // {
            //   key: "listing",
            //   icon: <FileOutlined />,
            //   label: "Listing",
            //   style: { display: ShowMenu(["Listing"]) },
            //   onClick: () => {
            //     navigate("/seller/listing");
            //   },
            // },
            {
              key: "donation-type",
              icon: <FileOutlined />,
              label: "Donation Type",
              style: { display: ShowMenu(["Donation Type"]) },
              onClick: () => {
                navigate("/seller/donation-type");
              },
            },
            {
              key: "service",
              icon: <FileOutlined />,
              label: "Service",
              style: { display: ShowMenu(["Service"]) },
              onClick: () => {
                navigate("/seller/service");
              },
            },
            {
              key: "product",
              icon: <ProjectOutlined />,
              label: "Product",
              style: { display: ShowMenu(["Product"]) },
              onClick: () => {
                navigate("/seller/product");
              },
            },
            // {
            //   key: "package",
            //   icon: <GroupOutlined />,
            //   label: "Package",
            //   style: { display: ShowMenu(["Package"]) },
            //   onClick: () => {
            //     navigate("/seller/package");
            //   },
            // },
            // // {
            // //   key: "sale-all",
            // //   icon: <ExportOutlined />,
            // //   label: "Sale",
            // //   style: {
            // //     display: ShowMenu([
            // //       "Sale",
            // //       "Pending",
            // //       "Confirmed",
            // //       "Processing",
            // //       "Out For Delivery",
            // //       "Delivered",
            // //       "Returned",
            // //       "Canceled",
            // //     ]),
            // //   },
            // //   children: [
            // //     {
            // //       key: "sale",
            // //       label: "Sale",
            // //       icon: <ExportOutlined />,
            // //       style: { display: ShowMenu(["Sale"]) },
            // //       onClick: () => {
            // //         navigate("/seller/sale");
            // //       },
            // //     },

            // //     {
            // //       key: "pending",
            // //       label: "Pending",
            // //       icon: <WeiboSquareFilled />,
            // //       style: { display: ShowMenu(["Pending"]) },
            // //       onClick: () => {
            // //         navigate("/seller/sale/pending");
            // //       },
            // //     },
            // //     {
            // //       key: "confirmed",
            // //       label: "Confirmed",
            // //       icon: <i className="fa fa-check"></i>,
            // //       style: { display: ShowMenu(["Confirmed"]) },
            // //       onClick: () => {
            // //         navigate("/seller/sale/confirmed");
            // //       },
            // //     },
            // //     {
            // //       key: "processing",
            // //       label: "Processing",
            // //       icon: <i className="fa fa-repeat"></i>,
            // //       style: { display: ShowMenu(["Processing"]) },
            // //       onClick: () => {
            // //         navigate("/seller/sale/processing");
            // //       },
            // //     },
            // //     {
            // //       key: "out-for-delivery",
            // //       label: "Out For Delivery",
            // //       icon: <i className="fa fa-outdent"></i>,
            // //       style: { display: ShowMenu(["Out For Delivery"]) },
            // //       onClick: () => {
            // //         navigate("/seller/sale/out-for-delivery");
            // //       },
            // //     },
            // //     {
            // //       key: "delivered",
            // //       label: "Delivered",
            // //       icon: <i className="fa fa-truck"></i>,
            // //       style: { display: ShowMenu(["Delivered"]) },
            // //       onClick: () => {
            // //         navigate("/seller/sale/delivered");
            // //       },
            // //     },
            // //     {
            // //       key: "returned",
            // //       label: "Returned",
            // //       icon: <i className="fa fa-repeat"></i>,
            // //       style: { display: ShowMenu(["Returned"]) },
            // //       onClick: () => {
            // //         navigate("/seller/sale/returned");
            // //       },
            // //     },
            // //     {
            // //       key: "canceled",
            // //       label: "Canceled",
            // //       icon: <i className="fa fa-user-times"></i>,
            // //       style: { display: ShowMenu(["Canceled"]) },
            // //       onClick: () => {
            // //         navigate("/seller/sale/canceled");
            // //       },
            // //     },
            // //   ],
            // // },
            // // {
            // //   key: "blog-list",
            // //   icon: <i className="fa fa-shopping-cart"></i>,
            // //   label: "Blogs",
            // //   style: { display: ShowMenu(["Blogs"]) },
            // //   onClick: () => {
            // //     navigate("/seller/blog-list");
            // //   },
            // // },
            // // {
            // //   key: "setting",
            // //   icon: <i className="fa fa-shopping-cart"></i>,
            // //   label: "Shop",
            // //   style: { display: ShowMenu(["Setting"]) },
            // //   onClick: () => {
            // //     navigate("/seller/setting");
            // //   },
            // // },
            // // {
            // //   key: "bankinformation",
            // //   icon: <i className="fa fa-bank"></i>,
            // //   label: "Bank information",
            // //   style: { display: ShowMenu(["Bank information"]) },
            // //   onClick: () => {
            // //     navigate("/seller/bankinformation");
            // //   },
            // // },
            // // {
            // //   key: "my-income",
            // //   icon: <i className="fa fa-money"></i>,
            // //   label: "My Income",
            // //   style: { display: ShowMenu(["My Income"]) },
            // //   onClick: () => {
            // //     navigate("/seller/my-income");
            // //   },
            // // },
            // // {
            // //   key: "withdrawal",
            // //   icon: <i className="fa fa-object-group"></i>,
            // //   label: "Withdrawal",
            // //   style: { display: ShowMenu(["Withdrawal"]) },
            // //   onClick: () => {
            // //     navigate("/seller/withdrawal");
            // //   },
            // // },
            // // {
            // //   key: "job-all",
            // //   icon: <ExportOutlined />,
            // //   label: "Job Portal",
            // //   style: {
            // //     display: ShowMenu(["Job list", "Applied Jobs"]),
            // //   },
            // //   children: [
            // //     {
            // //       key: "joblist",
            // //       label: "Job list",
            // //       icon: <ExportOutlined />,
            // //       style: { display: ShowMenu(["Job list"]) },
            // //       onClick: () => {
            // //         navigate("/seller/joblist");
            // //       },
            // //     },
            // //     {
            // //       key: "add-jobs",
            // //       label: "Post a new job",
            // //       icon: <ExportOutlined />,
            // //       style: { display: ShowMenu(["Post a new job"]) },
            // //       onClick: () => {
            // //         navigate("/seller/job/add-jobs");
            // //       },
            // //     },

            //     {
            //       key: "applied-jobs",
            //       label: "Applied Jobs",
            //       icon: <WeiboSquareFilled />,
            //       style: { display: ShowMenu(["Applied Jobs"]) },
            //       onClick: () => {
            //         navigate("/seller/applied-jobs");
            //       },
            //     },
            //   ],
            // },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
