// import { allapiAction } from "@/Redux/common/action";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";

function FooterNew() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [menu, setmenu] = useState(false);
  const [menucate, setmenucate] = useState(false);
  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );
  const get_footer_all_data = useSelector((state) =>
    state?.allapi?.get_footer_all_data ? state?.allapi?.get_footer_all_data : {}
  );

  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [maincateindex, setmaincateindex] = useState("");
  const [subcateindex, setsubncateindex] = useState("0");

  const hendletocateShow = (e) => {
    setmaincateindex(e);
    setsubncateindex("0");
  };
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  console.log(get_invoice);

  useEffect(() => {
    dispatch(allapiAction.footerPagepageListetPu({}));
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));
    } else {
      setValue("");
      setValuevendor("");
    }
    dispatch(allapiAction.getInvoice());
  }, []);
  const hendlemenu = () => {
    if (menu) {
      setmenu(false);
    } else {
      setmenu(true);
    }
  };

  return (
    <div className="footerNew">
      <footer class="footer-section">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="widget company-intro-widget">
                  <a href="index.html" class="site-logo">
                    {/* <img
                      src="https://i.ibb.co/vLDyPtM/ak-logo-yellow.png"
                      alt="logo"
                      class="footer_logo"
                    /> */}
                    <a class="ps-logo" href="/">
                      {/* <span class="gradient-color-text">BestBharat</span>{" "} */}
                      <img
                        src="/img/logo.webp"
                        alt="logo"
                        class="footer_logo"
                      />
                    </a>
                  </a>
                  {/* <p>
                    BestBharat is a one-stop solution for all your needs - be it
                    online shopping on our e-commerce portal, reporting lost
                    items on our Lost & Found portal or finding jobs on our Job
                    portal. We strive to make your life simpler and hassle-free.
                  </p> */}
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="widget course-links-widget">
                  <h5 class="widget-title">Useful Links</h5>
                  <ul class="courses-link-list">
                    <li>
                      <Link to="/about-us">
                        <i class="fas fa-long-arrow-alt-right"></i>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/all-donation-type">
                        <i class="fas fa-long-arrow-alt-right"></i>Donate Now
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/conservatism">
                        <i class="fas fa-long-arrow-alt-right"></i>Conservatism
                      </Link>
                    </li>
                    <li>
                      <Link to="/all-message">
                        <i class="fas fa-long-arrow-alt-right"></i>Message
                      </Link>
                    </li>
                    <li>
                      <Link to="/student">
                        <i class="fas fa-long-arrow-alt-right"></i>Student
                      </Link>
                    </li> */}
                    {/* <li>
                      <a href="#">
                        <i class="fas fa-long-arrow-alt-right"></i>Apps
                        Development
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="widget course-links-widget">
                  <h5 class="widget-title">Consumer Policy</h5>
                  <ul class="courses-link-list">
                    <li>
                      <Link to="/privacy-policy">
                        <i class="fas fa-long-arrow-alt-right"></i>Privacy
                        Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-and-condition">
                        <i class="fas fa-long-arrow-alt-right"></i>Terms &
                        Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/return-and-refund-policy">
                        <i class="fas fa-long-arrow-alt-right"></i>Return &
                        Refund Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/cancellation-policy">
                        <i class="fas fa-long-arrow-alt-right"></i>Cancellation
                        Policy
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/job-portal">
                        <i class="fas fa-long-arrow-alt-right"></i>Job Portal
                      </Link>
                    </li>
                    <li>
                      <Link to="/conservatism">
                        <i class="fas fa-long-arrow-alt-right"></i>Conservatism
                      </Link>
                    </li>
                    <li>
                      <Link to="/all-message">
                        <i class="fas fa-long-arrow-alt-right"></i>Message
                      </Link>
                    </li>
                    <li>
                      <Link to="/student">
                        <i class="fas fa-long-arrow-alt-right"></i>Student
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="widget latest-news-widget">
                  <h5 class="widget-title">lastest news</h5>
                  <ul class="small-post-list">
                    <li>
                      <div class="small-post-item">
                        <a href="#" class="post-date">
                          July 18, 2018
                        </a>
                        <h6 class="small-post-title">
                          <a href="#">
                            Lorem Ipsum is simply dummy text of the printing.
                          </a>
                        </h6>
                      </div>
                    </li>
                    <li>
                      <div class="small-post-item">
                        <a href="#" class="post-date">
                          July 28, 2018
                        </a>
                        <h6 class="small-post-title">
                          <a href="#">
                            Lorem Ipsum is simply dummy text of the printing
                          </a>
                        </h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="widget newsletter-widget">
                  <h5 class="widget-title">Contact us</h5>
                  <div class="footer-newsletter">
                    <ul class="company-footer-contact-list">
                      <li>
                        <i class="fas fa-envelope"></i>
                        {get_invoice?.email}
                      </li>
                      <li>
                        <i class="fas fa-phone"></i>
                        {get_invoice?.number}
                      </li>
                      <li>
                        <i class="fas fa-map-marker-alt"></i>{" "}
                        {get_invoice?.address}
                      </li>

                      {/* <li>
                        <i class="fas fa-clock"></i>Mon - Sat 8.00 - 18.00
                      </li> */}
                    </ul>
                    {/* <p>
                      Sign Up to Our Newsletter to Get Latest Updates & Services
                    </p> */}
                    {/* <form class="news-letter-form">
                      <input
                        type="email"
                        name="news-email"
                        id="news-email"
                        placeholder="Your email address"
                      />
                      <input type="submit" value="Send" />
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-3 text-sm-left text-center"></div>
              <div class="col-md-6 col-sm-6 text-sm-left text-center">
                <span class="copy-right-text">
                  ©2024 <a href="#">Aghoreshwara </a>
                  All Rights Reserved.
                </span>
              </div>
              {/* <div class="col-md-6 col-sm-6">
                <ul class="terms-privacy d-flex justify-content-sm-end justify-content-center">
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      <div className="navigation--list">
        <div className="navigation__content">
          <Link
            className="navigation__item ps-toggle--sidebar footico"
            to="/"
            onClick={() => {
              setmenucate(false);
              setmenu(false);
            }}
          >
            <i className="icon-home"></i>
            <span> Home</span>
          </Link>
          {/* <a
            className="navigation__item ps-toggle--sidebar footico"
            onClick={() => {
              hendlemenu(true);
              setmenucate(false);
            }}
          >
            <i className="icon-menu"></i>
            <span> Menu</span>
          </a> */}

          <a
            className="navigation__item ps-toggle--sidebar footico "
            onClick={() => {
              setmenucate(true);
              setmenu(false);
            }}
          >
            <i className="icon-list4"></i>
            <span> Explore</span>
          </a>
          <Link
            className="navigation__item ps-toggle--sidebar footico"
            to="/all-donation-type"
            onClick={() => {
              setmenucate(false);
              setmenu(false);
            }}
          >
            {/* <i className="icon-magnifier"></i> */}
            <i class="fa fa-gift" aria-hidden="true"></i>
            <span>Donation</span>
          </Link>
          {/* <Link
            className="navigation__item ps-toggle--sidebar footico"
            to="/all-videos"
            onClick={() => {
              setmenucate(false);
              setmenu(false);
            }}
          >
     
            <i class="fa fa-video-camera" aria-hidden="true"></i>

            
            <span>Video</span>
          </Link> */}
          <Link
            className="navigation__item ps-toggle--sidebar footico"
            to={value ? "/accounts" : "/login"}
            onClick={() => {
              setmenucate(false);
              setmenu(false);
            }}
          >
            {/* <img src="/img/public-service.png" width={20} loading="lazy" /> */}
            <i className="icon-user"></i>

            <span>My Profile</span>
          </Link>
        </div>
      </div>
      <div
        className={menucate ? "ps-panel--sidebar active " : "ps-panel--sidebar"}
        id="navigation-mobile"
      >
        <div className="ps-panel__header cth">
          <h3>Explore</h3>

          <div className="closebtnn">
            <img
              src="/img/close.png"
              onClick={() => {
                setmenucate(false);
              }}
              alt=""
            />
          </div>
        </div>
        <div className="mt-40"></div>
        <div className="row">
          <div className="col-4">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/p/history");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-history "
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#f62a17",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm">history</span>
              </a>
            </div>
          </div>
          <div className="col-4">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/branch-list");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-code-branch"
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#836946",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm">Branches</span>
              </a>
            </div>
          </div>
          <div className="col-4">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/p/about-us");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-info-circle"
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#A29DDF",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm">About us</span>
              </a>
            </div>
          </div>
          <div className="col-4 mt-40">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/listings");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-cog"
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#D98CC4",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm">Services</span>
              </a>
            </div>
          </div>
          <div className="col-4  mt-40">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/all-donation-type");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-heart "
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#E5CF84",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm">Donation</span>
              </a>
            </div>
          </div>
          <div className="col-4  mt-40">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/products");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-cart-plus "
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#B7D99C",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm">products</span>
              </a>
            </div>
          </div>

          <div className="col-4 mt-40">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/mix-videos");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-video-camera "
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#f62a17",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm">video</span>
              </a>
            </div>
          </div>
          <div className="col-4 mt-40">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/gallery");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-picture-o"
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#836946",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm">Gallery</span>
              </a>
            </div>
          </div>
          {/* <div className="col-4 mt-40">
            <div className="text-center">
              <a
                onClick={() => {
                  router("/p/about-us");
                  setmenucate(false);
                }}
                className="d-block"
              >
                <i
                  className="fa fa-info-circle"
                  style={{
                    fontSize: "20px",
                    color: "#ffffff",
                    background: "#A29DDF",
                    padding: "20px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                ></i>

                <span className="d-block mt-15 font-sm" >About us</span>
              </a>
            </div>
          </div> */}
        </div>

        {/* <div className="ps-panel__content mobile-header-content-area">
          <div className="mobile-menu-wrap mobile-header-border">
            <nav>
              <ul className="mobile-menu font-heading">
                {all_categories_List?.map((maincateData, inmain) => {
                  return (
                    <li
                      className={
                        maincateindex == inmain
                          ? "menu-item-has-children active"
                          : "menu-item-has-children"
                      }
                      key={inmain}
                    >
                      <span className="menu-expand">
                        <i className="fi-rs-angle-small-down"></i>
                      </span>
                      <a
                        onClick={() => {
                          hendletocateShow(inmain);
                        }}
                      >
                        {maincateData?.name}
                      </a>
                      <ul
                        className="dropdown"
                        style={
                          maincateindex == inmain ? {} : { display: "none" }
                        }
                      >
                        {maincateData?.subcates &&
                          maincateData?.subcates?.map((datasub, subind) => {
                            return (
                              <li
                                className={
                                  subind == subcateindex
                                    ? "menu-item-has-children active  "
                                    : "menu-item-has-children"
                                }
                                key={subind}
                              >
                                <span className="menu-expand">
                                  <i className="fi-rs-angle-small-down"></i>
                                </span>
                                <a
                                  href="#"
                                  onClick={() => {
                                    setsubncateindex(subind);
                                  }}
                                >
                                  {datasub?.name}
                                </a>

                                <ul
                                  className="dropdown"
                                  style={
                                    subind == subcateindex
                                      ? {}
                                      : { display: "none" }
                                  }
                                >
                                  {datasub?.subjectss &&
                                    datasub?.subjectss?.map((data, i) => {
                                      return (
                                        i < 9 && (
                                          <li key={i}>
                                            <a
                                              onClick={() => {
                                                router(
                                                  "/category-product/" +
                                                    data?.cate_sulg
                                                );
                                                setmenucate(false);
                                              }}
                                            >
                                              {data?.name}
                                            </a>
                                          </li>
                                        )
                                      );
                                    })}
                                  <li>
                                    {datasub?.subjectss?.length > 9 && (
                                      <Link
                                        to={"/category"}
                                        className="tabBtn1 active"
                                      >
                                        more
                                      </Link>
                                    )}
                                  </li>
                                </ul>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div> */}
      </div>

      {/* <div
        className={menu ? "ps-panel--sidebar active" : "ps-panel--sidebar"}
        id="menu-mobile"
      >
        <div className="ps-panel__header">
          <h3>Menu</h3>
        </div>


        <div className="ps-panel__content">
          <ul className="menu--mobile">
            <li className="menu-item-has-children">
              <Link
                to="/"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Home
              </Link>
            </li>
            <li className="menu-item-has-children">
              <Link
                to="/conservatism"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Conservatism
              </Link>
            </li>
            <li className="menu-item-has-children">
              <Link
                to="/student"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Student
              </Link>
            </li>
            {(value || valuevendor) && (
              <li className="menu-item-has-children">
                <Link
                  to="/accounts"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Accounts
                </Link>
              </li>
            )}
           
            {valuevendor && (
              <li className="menu-item-has-children">
                <Link
                  to="/seller"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Deshboard
                </Link>
              </li>
            )}
            {(value || valuevendor) && (
              <li className="menu-item-has-children">
                <Link
                  to="/add-lost-found"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Add Lost And Found
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div> */}

      <div
        className={menu ? "ps-panel--sidebar active" : "ps-panel--sidebar"}
        id="menu-mobile"
      >
        <div className="ps-panel__header">
          <h3>Menu</h3>
        </div>
        <div className="ps-panel__content text-center">
          <div class="row">
            <div class="col-3">
              <Link
                to="/"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-home my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Home</p>
              </Link>
            </div>
            <div class="col-3">
              <Link
                to="/all-donation-type"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-commenting my_icons_list"></i>
                <p class="menu_p_tag">Donation</p>
              </Link>
            </div>
            <div class="col-3">
              <Link
                to="/blogs-news"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-group my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">News</p>
              </Link>
            </div>
            <div class="col-3">
              <Link
                to="/products"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-group my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Product</p>
              </Link>
            </div>
            {/* <div class="col-3">
              <Link
                to="/accounts"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-bank my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Accounts</p>
              </Link>
            </div> */}
            {/* <div class="col-3">
              <Link
                to="/vendor-login"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i
                  class="fa fa-user-secret my_icons_list"
                  aria-hidden="true"
                ></i>
                <p class="menu_p_tag">Become A Vendor</p>
              </Link>
            </div> */}
            {/* <div class="col-3">
              <Link
                to="/seller"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-dashboard my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Deshboard</p>
              </Link>
            </div> */}
            <div class="col-3">
              <Link
                to="/all-videos"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                {/* <i class="fa fa-dashboard my_icons_list" aria-hidden="true"></i> */}
                <i class="fa fa-video-camera" aria-hidden="true"></i>
                <p class="menu_p_tag">Video</p>
              </Link>
            </div>
            {/* <div class="col-3">
              <Link
                to={value || valuevendor ? "/message" : "/login"}
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                <i class="fa fa-dashboard my_icons_list" aria-hidden="true"></i>
                <p class="menu_p_tag">Add Message</p>
              </Link>
            </div> */}
          </div>

          {/* <ul className="menu--mobile">
            <li className="menu-item-has-children">
              <Link
                href="/"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Home
              </Link>
            </li>
            <li className="menu-item-has-children">
              <Link
                href="/conservatism"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Conservatism
              </Link>
            </li>
            <li className="menu-item-has-children">
              <Link
                href="/student"
                onClick={() => {
                  hendlemenu(true);
                }}
              >
                Student
              </Link>
            </li>
            {(value || valuevendor) && (
              <li className="menu-item-has-children">
                <Link
                  href="/accounts"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Accounts
                </Link>
              </li>
            )}
            {!valuevendor && !value && (
              <li className="menu-item-has-children">
                <Link
                  href="/vendor-login"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Become A Vendor
                </Link>
              </li>
            )}
            {valuevendor && (
              <li className="menu-item-has-children">
                <Link
                  href="/seller"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Deshboard
                </Link>
              </li>
            )}
            {(value || valuevendor) && (
              <li className="menu-item-has-children">
                <Link
                  href="/add-lost-found"
                  onClick={() => {
                    hendlemenu(true);
                  }}
                >
                  Add Lost And Found
                </Link>
              </li>
            )}
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default FooterNew;
