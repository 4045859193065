import React from "react";

function CancellationPolicy() {
  return (
    <div className="container">
      <div className="mt-20"></div>
      <h1>Cancellation Policy</h1>

      <p>
        At Aghoreshwara, we strive to provide quality products and meaningful
        services that resonate with the sacred rituals and causes supported by
        the Hindu community. Our cancellation policy is designed to offer
        flexibility while ensuring the integrity of both our product sales and
        donations.
      </p>

      <h2>Cancellation Policy for Product Orders:</h2>
      <ul>
        <li>
          <strong>Timeframe for Cancellations:</strong>
          <ul>
            <li>
              Orders for physical products such as pooja-related items, Hindu
              religious photo frames, and t-shirts can be canceled within 24
              hours of placing the order.
            </li>
            <li>
              After 24 hours, cancellations will not be accepted as we initiate
              the processing and shipment of your order.
            </li>
          </ul>
        </li>

        <li>
          <strong>Non-Cancellable Items:</strong>
          <ul>
            <li>
              Any product that is customized or made-to-order cannot be canceled
              after the order is confirmed.
            </li>
            <li>
              Items that are part of a limited edition or special occasion sale
              are also non-cancellable.
            </li>
          </ul>
        </li>

        <li>
          <strong>Refund for Canceled Orders:</strong>
          <ul>
            <li>
              For eligible cancellations, refunds will be processed within 7-10
              business days after the cancellation request is approved. The
              refund will be credited back to the original payment method used
              during the transaction.
            </li>
          </ul>
        </li>

        <li>
          <strong>Shipping Charges:</strong>
          <ul>
            <li>
              If the order has already been shipped, it cannot be canceled, and
              shipping charges will be non-refundable.
            </li>
          </ul>
        </li>
      </ul>

      <h2>Cancellation Policy for Donations:</h2>
      <ul>
        <li>
          <strong>Donations are Non-Refundable:</strong>
          <ul>
            <li>
              Once a donation is made towards any cause—whether for an old age
              home, gau daan (cow donation), farmer support, orphan support, or
              Aghoreshwar ashram charity—it is considered final and
              non-refundable.
            </li>
            <li>
              Donations are directly used to support the causes and initiatives,
              and thus cannot be canceled or refunded.
            </li>
          </ul>
        </li>

        <li>
          <strong>Payment Errors:</strong>
          <ul>
            <li>
              If a payment error occurs (such as duplicate donations), please
              contact our support team within 48 hours of the transaction. In
              such cases, we will review the error and process refunds or
              corrections accordingly.
            </li>
          </ul>
        </li>
      </ul>

      <h2>How to Cancel an Order:</h2>
      <p>
        To cancel an eligible order, please log in to your account on the
        Aghoreshwara application and navigate to the "My Orders" section. Select
        the order you wish to cancel and follow the prompts for cancellation.
      </p>
      <p>
        Alternatively, you can contact our customer support team at{" "}
        <a href="mailto:customerservice@aghoreshwara.org">
          customerservice@aghoreshwara.org
        </a>{" "}
        within the cancellation window.
      </p>

      <h2>For Further Assistance:</h2>
      <p>
        If you have any questions regarding cancellations, refunds, or
        donations, feel free to reach out to our customer support team at{" "}
        <a href="mailto:customerservice@aghoreshwara.org">
          customerservice@aghoreshwara.org
        </a>{" "}
        or call us at +91 93056 28315.
      </p>
    </div>
  );
}

export default CancellationPolicy;
