import React from 'react'
import AllVideoShort from './AllVideoShort'
import AllVideoFullVideo from './AllVideoFullVideo'

function MixVideo() {
  return (
    <div>
    <AllVideoShort/>
    <AllVideoFullVideo/>
    
    </div>
  )
}

export default MixVideo