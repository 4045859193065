// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import { toast } from "react-toastify";
import axios from "axios";
function Checkout() {
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const loading_checkOut = useSelector((state) =>
    state?.allapi?.loading_checkOut ? state?.allapi?.loading_checkOut : false
  );
  console.log(loading_checkOut);
  const get_user_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  console.log(get_all_cart_itme);
  console.log(get_user_profile);
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);
  const totalSubtotalshipping = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.delivery_charges;
  }, 0);
  const totalSubtotalPricegst = get_all_cart_itme.reduce((total, obj) => {
    return total + (obj.subtotalprice * obj?.gst) / 100;
  }, 0);
  const [payMethod, setpayMethod] = useState("");
  console.log(payMethod);
  // createOrder
  const dispatch = useDispatch();

  const router = useNavigate();

  const orderSumit = (e) => {
    // createOrder

    // if (PaymentMethod == "online") {
    //   displayRazorpay();
    // } else {
      const orderAddress = localStorage.getItem("order_Address");

      console.log(orderAddress);
      const obj = JSON.parse(orderAddress);
      console.log(obj);
    if (!payMethod) {
      return toast.error("Please Select Payment Method");
    }
    if (payMethod == "online") {
      checkoutHandler({ name:get_user_profile?.fullName, amount: totalAmount,address_id: obj,delivery_charges:0 });
      // return toast.error("Now not avalable online payment method");
      return;
    }
    

    dispatch(
      allapiAction.createOrder(
        {
          user_name: get_user_profile?.fullName,
          user_email: get_user_profile?.email,
          user_mobile: get_user_profile?.phone,
          delivery_charges: "0",
          sub_total: totalSubtotalPrice,
          address_id: obj,
          payment_mode_name: payMethod,
          // products_ids: commentsData,
        },
        router
      )
    );
    return () => {};
    // }
  };
  // const shippingCharge = totalSubtotalPrice > 10000 ? 699 : 199;
  const shippingCharge = totalSubtotalshipping;

  const totalAmount = totalSubtotalPrice + shippingCharge;
  // const totalAmount =
  //   totalSubtotalPrice + shippingCharge + totalSubtotalPricegst;

  useEffect(() => {
    // getprofile
    dispatch(allapiAction.getprofile({}));
  }, []);

  const checkoutHandler = async ({ name, amount,address_id,delivery_charges}) => {
    const token = await localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const {
      data: { order },
    } = await axios.post(
      URL.API_BASE_URL + "/sellerApi/checkout",
      {
        name,
        amount,
        address_id,
        delivery_charges,
        email:get_user_profile?.email,
        contact:  get_user_profile?.phone,
        orderType: "all",
      },
      config
    );

    console.log(order);

    // rzp_test_fa1tcuhWuZinSi
    // rzp_test_Wfjgw9yyFXbDbN
    const options = {
      key: "rzp_test_Wfjgw9yyFXbDbN", // Enter the Key ID generated from the Dashboard
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Payment razorpay",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // "handler": function (response: any) {
      //   alert(response.razorpay_payment_id);
      //   alert(response.razorpay_order_id);
      //   alert(response.razorpay_signature)
      // },
      callback_url: `${URL.API_BASE_URL}/publicApi/verification`,
      prefill: {
        name: name,
        email:get_user_profile?.email,
        contact:  get_user_profile?.phone,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rzp1 = new window.Razorpay(options);

    rzp1.open();
  };

  return (
    <div>
      <div className="ps-checkout ps-section--shopping">
        {/* <section class="bg-light my-5">
          <div class="container">
            <div class="row">
           
              <div class="col-lg-9">
                <div class="card border shadow-0">
                  <div class="m-4">
                    <h4 class="card-title mb-4">Your shopping cart</h4>

                    {get_all_cart_itme &&
                      get_all_cart_itme?.map((data, i) => {
                        console.log(data);
                        return (
                          <div class="row gy-3 mb-4">
                            <div class="col-lg-8">
                              <div class="me-lg-5">
                                <div class="d-flex">
                                  <img
                                    src={URL?.API_BASE_URL + data?.featureImage}
                                    class="border rounded me-3"
                                    style={{ width: "96px", height: "96px" }}
                                  />
                                  <div class="">
                                    <a href="#" class="nav-link">
                                      {data?.title}
                                    </a>
                                    <p class="text-muted">
                                      {" "}
                                      {data?.size},{" "}
                                      {data?.productcolorId?.color}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                              <div class="">
                               
                              </div>
                              <div class="">
                                <text class="h6">${data?.subtotalprice}</text>{" "}
                                <br />
                                <small class="text-muted text-nowrap">
                                  {" "}
                                  ${data?.afterdiscountprice}/ per item{" "}
                                </small>
                              </div>
                            </div>
                           
                          </div>
                        );
                      })}
                   

                    
                  </div>

                  <div class="border-top pt-4 mx-4 mb-4">
                     
                  </div>
                </div>
              </div>
            
              <div class="col-lg-3">
                <div class="card mb-3 border shadow-0">
                  <div class="card-body">
                    <form>
                      <div class="form-group">
                        <label class="form-label">Have coupon?</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control border"
                            name=""
                            placeholder="Coupon code"
                          />
                          <button class="btn btn-light border">Apply</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="card shadow-0 border">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <p class="mb-2">Total price:</p>
                      <p class="mb-2">
                        ₹{totalSubtotalPrice - totalSubtotalPricegst}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="mb-2">Shipping:</p>
                      <p class="mb-2 text-success">₹{shippingCharge}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="mb-2">TAX:</p>
                      <p class="mb-2">₹{totalSubtotalPricegst}</p>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between">
                      <p class="mb-2">Total price:</p>
                      <p class="mb-2 fw-bold">₹{totalAmount} </p>
                    </div>

                    <div class="mt-3">
                      <a
                        href="#"
                        onClick={() => {
                          orderSumit();
                        }}
                        class="btn btn-success w-100 shadow-0 mb-2"
                      >
                        {" "}
                        Proceed to checkout
                      </a>
                      
                    </div>
                  </div>
                </div>
              </div>
           
            </div>
          </div>
        </section> */}
        {loading_checkOut ? (
          <div className="notfound">
            <h2>loading...</h2>
          </div>
        ) : (
          <section class="bg-light   pt-20 pb-20">
            <div class="container">
              <div class="row">
                <div class="col-lg-9">
                  <div class="card border shadow-0">
                    <div class="m-4">
                      <h4 class="card-title mb-4">Your shopping cart</h4>

                      {get_all_cart_itme &&
                        get_all_cart_itme?.map((data, i) => {
                          console.log(data);
                          return (
                            <div class="row gy-3 mb-4">
                              <div class="col-lg-8">
                                <div class="me-lg-5">
                                  <div class="d-flex">
                                    <img
                                      src={
                                        URL?.API_BASE_URL + data?.featureImage
                                      }
                                      class="border rounded me-3"
                                      style={{ width: "96px", height: "96px" }}
                                    />
                                    <div class="">
                                      <a href="#" class="nav-link">
                                        {data?.title}
                                      </a>
                                      <p class="text-muted ml-10">
                                        {" "}
                                        {data?.size},{" "}
                                        {data?.productcolorId?.color}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                                <div class=""></div>
                                <div class="">
                                  <text class="h6 ml-10">
                                    ${data?.subtotalprice}
                                  </text>{" "}
                                  <br />
                                  <small class="text-muted text-nowrap">
                                    {" "}
                                    ${data?.afterdiscountprice}/ per item{" "}
                                  </small>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <div class="border-top pt-4 mx-4 mb-4"></div>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="card mb-3 border shadow-0">
                    <div class="card-body">
                      <form>
                        <div class="form-group">
                          <label class="form-label">Have coupon?</label>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control border"
                              name=""
                              placeholder="Coupon code"
                            />
                            <button class="btn btn-light border">Apply</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="card mb-3 border shadow-0">
                    <div class="card-body">
                      <form>
                        <div class="form-group">
                          <label class="form-label">Payment Method</label>
                          <div class=" ">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                value="case"
                                id="flexCheckDefault"
                                name="paymentMethod"
                                onChange={(e) => {
                                  setpayMethod(e.target.value);
                                }}
                              />
                              <label
                                class="form-check-label ml-10"
                                for="flexCheckDefault"
                              >
                                cash
                              </label>
                            </div>
                            {/* <br /> */}
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                value="wallet"
                                id="flexCheckChecked"
                                name="paymentMethod"
                                onChange={(e) => {
                                  setpayMethod(e.target.value);
                                }}
                                // checked
                              />
                              <label
                                class="form-check-label  ml-10"
                                for="flexCheckChecked"
                              >
                                Wallet
                              </label>
                            </div>

                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="radio"
                                value="online"
                                id="flexCheckChecked1"
                                name="paymentMethod"
                                onChange={(e) => {
                                  setpayMethod(e.target.value);
                                }}
                                // checked
                              />
                              <label
                                class="form-check-label  ml-10"
                                for="flexCheckChecked1"
                              >
                                Online
                              </label>
                            </div>
                            {/* <button class="btn btn-light border">Apply</button> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="card shadow-0 border">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <p class="mb-2">Total price:</p>
                        <p class="mb-2">
                          ₹{totalSubtotalPrice - totalSubtotalPricegst}
                        </p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-2">Shipping:</p>
                        <p class="mb-2 text-success">₹{shippingCharge}</p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-2">TAX:</p>
                        <p class="mb-2">₹{totalSubtotalPricegst}</p>
                      </div>
                      <hr />
                      <div class="d-flex justify-content-between">
                        <p class="mb-2">Total price:</p>
                        <p class="mb-2 fw-bold">₹{totalAmount} </p>
                      </div>

                      <div class="mt-3">
                        <a
                          href="#"
                          onClick={() => {
                            orderSumit();
                          }}
                          class="btn btn-success w-100 shadow-0 mb-2"
                        >
                          {" "}
                          Proceed to checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

export default Checkout;
