import React from "react";

function PravicyPolicy() {
  return (
    <div className="container">
      <div className="mt-20"></div>
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Aghoreshwara, an e-commerce platform dedicated to providing
        products related to Hindu religious practices, including photo frames,
        t-shirts, and more. We also offer donation options for various
        charitable causes. Your privacy and security are of utmost importance to
        us. This Privacy Policy explains how we collect, use, and protect your
        information when you use our website and services.
      </p>

      <h4>1. Information We Collect</h4>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you create an account,
          make a purchase, or engage in other activities, we may collect
          personal information such as your name, email address, phone number,
          shipping address, and payment information.
        </li>
        <li>
          <strong>Payment Information:</strong> Payments are processed through a
          secure payment gateway. We do not store your credit card information;
          it is handled directly by the payment gateway provider.
        </li>
        <li>
          <strong>Donation Information:</strong> If you make a donation, we
          collect details about the donation amount and the specific cause you
          support, such as old age home support, gau daan, farmer support,
          orphan support, or charity for Aghoreshwara's ashram.
        </li>
        <li>
          <strong>Usage Data:</strong> We may collect information about how you
          interact with our website, including IP addresses, browser types, and
          access times, to improve our services.
        </li>
      </ul>

      <h4>2. How We Use Your Information</h4>
      <ul>
        <li>
          <strong>To Process Transactions:</strong> We use your personal and
          payment information to process your purchases and donations.
        </li>
        <li>
          <strong>To Provide and Improve Services:</strong> Your information
          helps us enhance your user experience, provide customer support, and
          improve our website and services.
        </li>
        <li>
          <strong>To Communicate with You:</strong> We may use your contact
          information to send you order confirmations, updates, promotional
          materials, and other relevant information.
        </li>
        <li>
          <strong>To Fulfill Donations:</strong> We use donation information to
          allocate funds to the respective causes and charities.
        </li>
      </ul>

      <h4>3. How We Protect Your Information</h4>
      <ul>
        <li>
          <strong>Data Security:</strong> We implement appropriate technical and
          organizational measures to safeguard your personal information from
          unauthorized access, disclosure, alteration, and destruction.
        </li>
        <li>
          <strong>Payment Security:</strong> Payments are processed through a
          secure payment gateway provider. We do not store your payment details
          on our servers.
        </li>
        <li>
          <strong>Access Controls:</strong> We limit access to your information
          to authorized personnel only, who need it to perform their job duties.
        </li>
      </ul>

      <h4>4. Sharing Your Information</h4>
      <ul>
        <li>
          <strong>With Service Providers:</strong> We may share your information
          with third-party service providers who assist us in processing
          transactions, handling payments, and delivering products.
        </li>
        <li>
          <strong>For Legal Compliance:</strong> We may disclose your
          information if required by law, to comply with legal obligations, or
          to protect our rights and safety.
        </li>
      </ul>

      <h4>5. Cookies and Tracking Technologies</h4>
      <ul>
        <li>
          <strong>Cookies:</strong> We use cookies and similar tracking
          technologies to enhance your browsing experience, remember your
          preferences, and analyze website usage. You can manage your cookie
          preferences through your browser settings.
        </li>
      </ul>

      <h4>6. Your Rights and Choices</h4>
      <ul>
        <li>
          <strong>Access and Update:</strong> You can access and update your
          personal information through your account settings.
        </li>
        <li>
          <strong>Opt-Out:</strong> You can opt-out of receiving promotional
          communications from us by following the unsubscribe instructions
          provided in the emails.
        </li>
      </ul>

      <h4>7. Changes to This Privacy Policy</h4>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any significant changes by posting the updated policy on our website.
        Your continued use of our services after such changes constitutes your
        acceptance of the revised policy.
      </p>

      <h4>8. Contact Us</h4>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at:
      </p>
      <p>
        <strong>Aghoreshwara Support</strong>
        <br />
        Email: support@aghoreshwara.com
        <br />
        Address: Avdhoot Bhagwan Ram Nagar, Post Pelhar, Taluka Vasai District -
        Palghar, Maharashtra, Pin 401208
      </p>
    </div>
  );
}

export default PravicyPolicy;
