import { Modal } from "antd";
// import Link from "next/link";
import React, { useState } from "react";
import Slider from "react-slick";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import { Link } from "react-router-dom";
import { URL } from "../../Redux/common/url";
// import { URL } from "@/Redux/common/url";

function ShortVideo({ homedata }) {
  const get_home_all_pro = homedata;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videodetal, setvideodetal] = useState({});

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const recentlisting = get_home_all_pro?.videoslistShort
    ? get_home_all_pro?.videoslistShort
    : [];

  var settings = {
    dots: false,
    infinite: true,
    // autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.videoslistShort?.length == 1
        ? 1
        : get_home_all_pro?.videoslistShort?.length > 7
          ? 7
          : get_home_all_pro?.videoslistShort?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.videoslistShort?.length == 1
              ? 1
              : get_home_all_pro?.videoslistShort?.length > 4
                ? 4
                : get_home_all_pro?.videoslistShort?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow:
            get_home_all_pro?.videoslistShort?.length == 1
              ? 1
              : get_home_all_pro?.videoslistShort?.length > 3
                ? 3
                : get_home_all_pro?.videoslistShort?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.videoslistShort?.length == 1
              ? 1
              : get_home_all_pro?.videoslistShort?.length > 2
                ? 2
                : get_home_all_pro?.videoslistShort?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Modal
        title="Video"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={800}
      >
        <iframe
          width="260"
          height="280"
          src={videodetal?.link}
          // src={"https://www.youtube.com/embed/LGtw6rpmXEw?si=lLE4ySa4rvreSq6t"}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal>
      <div className="ps-product-list ps-clothings">
        <div className="ps-container">
          <div className="ps-section__header">
            <div className="ps-block--countdown-deal">
              <div className="ps-block__left">
                <b>
                  {" "}
                  <h3>Video</h3>
                </b>
              </div>
            </div>
            <Link to="/all-video-short">View all</Link>
          </div>

          <div className="  mt-10">
            {recentlisting?.length > 0 && (
              <Slider {...settings}>
                {recentlisting &&
                  recentlisting?.map((data, i) => {
                    return (
                      <div className="videoBox" key={i}>
                        <div className="card">
                          <CoustomImg
                            url={
                              data?.thumbnail
                                ? URL.API_BASE_URL + data?.thumbnail
                                : ""
                            }
                            altkey={data?.title}
                            w1={300}
                            w2={200}
                            h1={290}
                            h2={320}
                          />
                          <div
                            className="playIcon"
                            onClick={() => {
                              window?.open(data?.link, "_blank");
                            }}
                          ></div>
                          <div className="ps-product__container text-center shortVideo">
                            <div className="ps-product__content">
                              <TruncateText text={data?.name} maxLength={20} />
                              <br />
                              <TruncateText
                                text={data?.description}
                                maxLength={20}
                              />
                            </div>
                            {/* <div className="ps-product__content hover">
                            
                            </div> */}
                          </div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShortVideo;
