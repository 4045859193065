import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function Aghoreshwara() {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div className="position-relative">
      <div className="container">
        {/* <img
          src="/img/aghori/Aghoreshwaraaboutus.webp"
          className="card-img-top"
          style={{ width: "100%" }}
          alt="..."
        /> */}
        <img
          src={"/img/aghori/Aghoreshwaraaboutus.webp"}
          alt={"ok"}
          className={`blur-image ${isLoaded ? "hide" : ""}`}
          style={{
            filter: "blur(20px)",
            transition: "opacity 0.3s ease-in-out",
            opacity: isLoaded ? 0 : 1,
          }}
        />
        <LazyLoadImage
          src={"/img/aghori/Aghoreshwaraaboutus.webp"}
          alt={"ok"}
          // width={imageWidth}
          // height={imageHeight}
          delayTime={300}
          onLoad={() => setIsLoaded(true)}
          style={{
            // Hide until loaded
            transition: "opacity 0.3s ease-in-out",
            opacity: isLoaded ? 1 : 0,
          }}
          // use normal <img> attributes as props
        />
      </div>
      <div className="container">
        <div className="text-center">
          <button className="btn btn-primary">
            <Link to={"/p/about-us"} style={{ color: "#ffffff" }}>
              Read More
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Aghoreshwara;
