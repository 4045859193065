import React, { useEffect, useState } from "react";
import OurWork from "../OurWork/OurWork";
import ImageUploading from "react-images-uploading";
// import { URL } from "@/Redux/common/url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import Api from "../../Redux/common/api";
import ImageCropper from "../ImageCroper/ImageCropper";
// import Api from "@/Redux/common/api";
// import { useRouter } from "next/router";
function GenerateCertificateCom() {
  const [lostfinddata, setlostfinddata] = useState({});
  const [images, setImages] = useState([]);
  const maxNumber = 1;
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const obj = JSON.parse(localStorage.getItem("access_user"));
      setValue(obj);
    } else {
      setValue("");
      setValuevendor("");
    }
  }, []);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const hendletochange = (e) => {
    const { name, value } = e?.target;
    setlostfinddata({ ...lostfinddata, [name]: value });
  };


  const [fileName, setFileName] = useState("");
  const [fileContant, setfileContant] = useState("");
  const [finalImg, setfinalImg] = useState("");

  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const hendleTosubmit = async () => {
    const data = new FormData();
    try {
      console.log(lostfinddata);
      if (!fileName) {
        toast.error("please select  Image");
      } /* else if (!lostfinddata?.name) {
        toast.error("please enter  name");
      } */ else if (!lostfinddata?.fatherName) {
        toast.error("please enter father name ");
      } else if (!lostfinddata?.crType) {
        toast.error("please select Conservatism ");
      } else if (!lostfinddata?.city) {
        toast.error("please enter city ");
      } else if (!lostfinddata?.state) {
        toast.error("please enter state ");
      } else {
        // setbtndis(true);
        data.append("name", value?.fullName);
        data.append("fatherName", lostfinddata?.fatherName);
        data.append("crType", lostfinddata?.crType);

        data.append("pincode", lostfinddata?.pincode);
        data.append("city", lostfinddata?.city);
        data.append("state", lostfinddata?.state);
        data.append("image", fileContant, fileName);
        // data.append("image", images[0]?.file);

        const token = await localStorage.getItem("access_token");

        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        if (localStorage.getItem("access_token")) {
          const response = await Api.post(
            `${URL.Certificatescreate}`,
            data,
            config
          );

          if (response?.data?.success) {
            //   setbtndis(false);
            toast.success(response?.data?.message);
            navigate("/winner/" + response?.data?.data?.slug);
          } else {
            //   setbtndis(false);
            toast.error(response?.data?.message);
          }
        } else {
          const token = await localStorage.getItem("access_token_vendor");

          const config = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };
          const response = await Api.post(
            `${URL.Certificatescreateseller}`,
            data,
            config
          );

          if (response?.data?.success) {
            //   setbtndis(false);
            toast.success(response?.data?.message);
            navigate("/winner/" + response?.data?.data?.slug);
          } else {
            //   setbtndis(false);
            toast.error(response?.data?.message);
          }
        }
      }
    } catch (error) {
      //   setbtndis(false);

      console.log(error);
    }
  };

  return (
    <div>
      <div className="container p-4">
        <div className="form-border p-5">
          <div className="mb-4">
            {/* <img
              src="image/certificate_2_logo_1-removebg-preview (1).png"
              alt="best_bharat_logo"
              className="form_logo"
            /> */}
          </div>
          {/* <form action=""> */}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <label for="">Name</label>
              <input
                type="text"
                name="name"
                // onChange={(e) => {
                //   hendletochange(e);
                // }}
                value={value?.fullName}
                className="form-control"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <label for="">Father 's name</label>
              <input
                type="text"
                name="fatherName"
                onChange={(e) => {
                  hendletochange(e);
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <label for="social">Choose</label>
              <select
                id="social"
                className="form-control"
                name="crType"
                onChange={(e) => {
                  hendletochange(e);
                }}
              >
                {/* <option value="audi">Choose.....</option>
                <option value="Dhej Prtha">Dhej Prtha</option>
                <option value="Mrityu Bhoj">Mrityu Bhoj</option> */}
                <option value="">select </option>
                <option value="Pranavayu Award">Pranavayu Award</option>
              </select>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <label for="img">Select your image :</label>
              <div>
                  {/* <h1>Image Cropper</h1> */}
                  <ImageCropper
                    aspectRatio={4 / 3}
                    // onImageCrop={handleImageCrop}
                    setfileContant={setfileContant}
                    setFileName={setFileName}
                    setfinalImg={setfinalImg}
                    aspectwidth={16}
                    aspectheight={16}
                    onCropComplete={setCroppedImageUrl}
                  />

{croppedImageUrl && (
                      <div>
                        <img
                          src={croppedImageUrl}
                          alt="Cropped"
                          width={100}
                          height={100}
                        />
                      </div>
                    )}
                  {/* {finalImg && (
                    <div>
                      <img
                        src={finalImg}
                        alt="Cropped"
                        width={100}
                        height={130}
                      />
                    </div>
                  )} */}
                </div>


 
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <label for="">City</label>
              <input
                type="text"
                className="form-control"
                name="city"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <label for="">State</label>
              <input
                type="text"
                className="form-control"
                name="state"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <label for="">Pincode</label>
              <input
                type="text"
                className="form-control"
                name="pincode"
                onChange={(e) => {
                  hendletochange(e);
                }}
              />
            </div>
          </div>

          {/* <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                <label for="">Description </label>
                <textarea className="form-control" rows="3"></textarea>
              </div>
            </div> */}
          <div className="d-flex justify-content-center">
            <a href="#">
              <button
                className="mt-15 btn btn-primary"
                onClick={(e) => {
                  hendleTosubmit(e);
                }}
              >
                SUBMIT
              </button>
            </a>
          </div>
          {/* </form> */}
        </div>
      </div>
      <div className="mt-25 mb-25">
        <OurWork />
      </div>
    </div>
  );
}

export default GenerateCertificateCom;
