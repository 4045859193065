import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SuccessPayment() {
  const router = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      router("/");
    }, 5000);
    return () => {};
  }, []);

  return (
    <div>
      <div className="success-container">
        <div className="success-card">
          <div className="checkmark-container">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark-circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark-check"
                fill="none"
                d="M16 26l9 9 13-13"
              />
            </svg>
          </div>
          <h1 className="success-title">Payment Successful!</h1>
          <p className="success-message">
            Thank you for your payment. Your transaction was completed
            successfully.
          </p>
          <button
            className="success-button"
            onClick={() => (window.location.href = "/")}
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessPayment;
