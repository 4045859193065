// import Image from "next/image";
import React, { useLayoutEffect, useState } from "react";
import { Image } from "react-bootstrap";

function CoustomImg2({ url, altkey, w1, w2, h1, h2 }) {
  const [windowWidth, setWindowWidth] = useState(600);

  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imageWidth = windowWidth <= 767 ? w1 : w2;
  const imageHeight = windowWidth <= 767 ? h1 : h2;
  return (
    <div>
      <Image
        className="default-img "
        src={url}
        alt={altkey}
        width={imageWidth}
        height={imageHeight}
        // layout="responsive"
      />
    </div>
  );
}

export default CoustomImg2;
