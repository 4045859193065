import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import { toast } from "react-toastify";

function DonationNow() {
  const [DonationData, setDonationData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

 
  const details = useSelector((state) =>
    state?.allapi?.get_donation_type_details
      ? state?.allapi?.get_donation_type_details
      : {}
  );

 

  //   details

  const [valuesetUserDeatils, setUserDeatils] = useState({});

  console.log(valuesetUserDeatils);

  useEffect(() => {
    if (typeof window !== "undefined") {
      //   setValue(localStorage.getItem("access_token"));
      //   setValuevendor(localStorage.getItem("access_token_vendor"));

      const userDetails = localStorage.getItem("access_user");
      if(!userDetails){
        navigate("/login")
      }
      setUserDeatils(JSON?.parse(userDetails));
    } else {
      //   setValue("");
      //   setValuevendor("");
      setUserDeatils({});
    }
  }, []);

  const { id } = useParams();
  const hendleToChange = (e) => {
    setDonationData({ ...DonationData, [e.target.name]: e.target.value });
  };
  const hendleToChange2 = (e) => {
    const value = e.target.value;
    setDonationData({
      ...DonationData,
      qty: value,
      amount: value * details?.price,
    });
  };

  const hendleToSubmit = async (e) => {
    e.preventDefault();
    // donationTypeDetails

    if (!DonationData?.father_name) {
      toast?.error("Please enter father name");
      return;
    }
    // if (!DonationData?.volunteer) {
    //   toast?.error("Please select volunteer");
    //   return;
    // }
    if (!DonationData?.amount) {
      toast?.error("Please enter amount");
      return;
    }

    DonationData.district = valuesetUserDeatils?.district;
    DonationData.city = valuesetUserDeatils?.city;
    DonationData.user_mobile = valuesetUserDeatils?.phone;
    DonationData.user_name = valuesetUserDeatils?.fullName;
    DonationData.state = valuesetUserDeatils?.state;
    DonationData.pincode = valuesetUserDeatils?.zip;
    DonationData.donation_id = details?._id;

    const response = await dispatch(allapiAction.donationSubmit(DonationData));

    if (response?.success) {
      navigate("/");
    }
  };

  useEffect(() => {
    dispatch(allapiAction.donationTypeDetails(id));

    return () => {};
  }, []);

  return (
    <div style={{ background: "#f4e60761" }}>
      <div class="container-flude">
        <img
          src={URL?.API_BASE_URL + details?.featureImage}
          class="donation_slider_image"
        />
      </div>
      <div class="container">
        <h3 class="text-center mt-3">Donater Form</h3>

        <div id="donationCard shadow-lg ">
          <div class="row">
            <form method="post">
              <div className="shadow-lg p-14 mb-15  contact_form">
                {/* <input
                type="hidden"
                name="_token"
                value="7bBV4fPHY6iDJMUgJ5CgNKkg9xBkUP5C1PcDnWPD"
              />

              <input
                type="hidden"
                class="form-control"
                id="admission_no"
                name="admission_no"
                placeholder="student.Admission No."
                readonly=""
                value="5"
                onkeypress="javascript:return isNumber(event)"
              />
              <input type="hidden" name="form_type" value="Donation" /> */}

                <div class="row">
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="name" class="custom-label">
                        Full Name Of Donor
                      </label>
                      <input
                        type="text"
                        class="form-control text_valid"
                        id="name"
                        placeholder="नाम"
                        name="name"
                        value={valuesetUserDeatils?.fullName}
                        onkeydown="return /[a-zA-Z ]/i.test(event.key)"
                      />
                      <span
                        class="invalid-feedback"
                        id="name_error"
                        role="alert"
                      >
                        <strong> Full Name can not be left blank</strong>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="name" class="custom-label">
                        Father Name
                      </label>
                      <input
                        type="text"
                        class="form-control text_valid"
                        id="father_name"
                        placeholder="पिता का नाम"
                        name="father_name"
                        // value=""
                        onChange={(e) => {
                          hendleToChange(e);
                        }}
                        onkeydown="return /[a-zA-Z ]/i.test(event.key)"
                      />
                      <span
                        class="invalid-feedback"
                        id="father_name_error"
                        role="alert"
                      >
                        <strong> Father Name can not be left blank</strong>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="mobile" class="custom-label">
                        Mobile
                      </label>
                      <input
                        type="tel"
                        class="form-control"
                        id="mobile"
                        placeholder="मोबाइल नं."
                        name="mobile"
                        value={valuesetUserDeatils?.phone}
                        pattern="[0-9]*"
                        maxlength="10"
                        onkeypress="javascript:return isNumber(event)"
                      />
                      <span
                        class="invalid-feedback"
                        id="mobile_error"
                        role="alert"
                      >
                        <strong> Mobile can not be left blank</strong>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="email" class="custom-label">
                        E-mail
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        placeholder="ई-मेल"
                        name="email"
                        value={valuesetUserDeatils?.email}
                      />
                    </div>
                  </div>
                  {/* <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="donatin_type" class="required custom-label">
                      Donation Type
                    </label>
                    <select
                      class="form-control "
                      id="donatin_type"
                      name="donatin_type"
                    >
                      <option value="">Select</option>
                      <option value="1">Gosala</option>
                      <option value="2">Rajbhog</option>
                      <option value="3">Dharamsala</option>
                      <option value="4">Dev Janmotsav</option>
                      <option value="5">Annakshetra</option>
                    </select>
                    <span
                      class="invalid-feedback"
                      id="donatin_type_error"
                      role="alert"
                    >
                      <strong> Donation Type can not be left blank</strong>
                    </span>
                  </div>
                </div> */}
                  {details?.fixprice == "Yes" && (
                    <div class="col-md-4 col-12" id="rajbhog_qty">
                      <div class="form-group">
                        <label for="rajbhog_amount" class="custom-label">
                          Quantity
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="qty_amt"
                          name="qty"
                          placeholder="मात्रा"
                          onChange={(e) => {
                            hendleToChange2(e);
                          }}
                          // value="1"
                        />
                      </div>
                    </div>
                  )}

                  {details?.fixprice == "Yes" ? (
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="mobile" class="custom-label">
                          Amount
                        </label>
                        <input
                          type="tel"
                          class="form-control"
                          id="amount"
                          placeholder="दान राशि"
                          name="amount"
                          value={DonationData?.amount}
                          // value=""
                          // onChange={(e) => {
                          //   hendleToChange(e);
                          // }}
                          maxlength="20"
                          onkeypress="javascript:return isNumber(event)"
                        />
                        <span
                          class="invalid-feedback"
                          id="amount_error"
                          role="alert"
                        >
                          <strong> Amount can not be left blank</strong>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="mobile" class="custom-label">
                          Amount
                        </label>
                        <input
                          type="tel"
                          class="form-control"
                          id="amount"
                          placeholder="दान राशि"
                          name="amount"
                          // value=""
                          value={valuesetUserDeatils?.amount}
                          onChange={(e) => {
                            hendleToChange(e);
                          }}
                          maxlength="20"
                          onkeypress="javascript:return isNumber(event)"
                        />
                        <span
                          class="invalid-feedback"
                          id="amount_error"
                          role="alert"
                        >
                          <strong> Amount can not be left blank</strong>
                        </span>
                      </div>
                    </div>
                  )}

                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="mobile" class="custom-label">
                        Pan No
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="pancardno"
                        placeholder="पैन कार्ड नंबर"
                        name="pancardno"
                        // value=""
                        onChange={(e) => {
                          hendleToChange(e);
                        }}
                        maxlength="10"
                      />
                      <span
                        class="invalid-feedback"
                        id="pan_no_error"
                        role="alert"
                      >
                        <strong> Pan Card No can not be left blank</strong>
                      </span>
                    </div>
                  </div>
                  {/* <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="village_city" class="custom-label">
                      Village
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="village_city"
                      placeholder="गाँव का नाम"
                      onChange={(e) => {
                        hendleToChange(e);
                      }}
                      name="village_city"
                      value=""
                    />
                  </div>
                </div> */}
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="pincode" class="custom-label">
                        Pincode
                      </label>
                      <input
                        type="tel"
                        class="form-control"
                        id="pincode"
                        placeholder="पिन कोड"
                        name="pincode"
                        value={valuesetUserDeatils?.zip}
                        maxlength="6"
                        //   onChange={(e) => {
                        //     hendleToChange(e);
                        //   }}
                        // onkeypress="javascript:return isNumber(event)"
                      />
                      <span
                        class="invalid-feedback"
                        id="amount_error"
                        role="alert"
                      >
                        <strong> Amount can not be left blank</strong>
                      </span>
                    </div>
                  </div>

                  {/* <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="State" class="required custom-label">
                      Country
                    </label>
                    <select
                      class="form-control country_id "
                      id="country_id"
                      name="country_id"
                      required=""
                      onChange={(e) => {
                        hendleToChange(e);
                      }}
                    >
                      <option value="">देश</option>
                      <option value="1">Afghanistan</option>
                      <option value="2">Aland Islands</option>
                      <option value="3">Albania</option>
                      <option value="4">Algeria</option>
                      <option value="5">American Samoa</option>
                      <option value="6">Andorra</option>
                      <option value="7">Angola</option>
                      <option value="8">Anguilla</option>
                      <option value="9">Antarctica</option>
                      <option value="10">Antigua And Barbuda</option>
                      <option value="11">Argentina</option>
                      <option value="12">Armenia</option>
                      <option value="13">Aruba</option>
                      <option value="14">Australia</option>
                      <option value="15">Austria</option>
                      <option value="16">Azerbaijan</option>
                      <option value="17">Bahamas The</option>
                      <option value="18">Bahrain</option>
                      <option value="19">Bangladesh</option>
                      <option value="20">Barbados</option>
                      <option value="21">Belarus</option>
                      <option value="22">Belgium</option>
                      <option value="23">Belize</option>
                      <option value="24">Benin</option>
                      <option value="25">Bermuda</option>
                      <option value="26">Bhutan</option>
                      <option value="27">Bolivia</option>
                      <option value="28">Bosnia and Herzegovina</option>
                      <option value="29">Botswana</option>
                      <option value="30">Bouvet Island</option>
                      <option value="31">Brazil</option>
                      <option value="32">British Indian Ocean Territory</option>
                      <option value="33">Brunei</option>
                      <option value="34">Bulgaria</option>
                      <option value="35">Burkina Faso</option>
                      <option value="36">Burundi</option>
                      <option value="37">Cambodia</option>
                      <option value="38">Cameroon</option>
                      <option value="39">Canada</option>
                      <option value="40">Cape Verde</option>
                      <option value="41">Cayman Islands</option>
                      <option value="42">Central African Republic</option>
                      <option value="43">Chad</option>
                      <option value="44">Chile</option>
                      <option value="45">China</option>
                      <option value="46">Christmas Island</option>
                      <option value="47">Cocos (Keeling) Islands</option>
                      <option value="48">Colombia</option>
                      <option value="49">Comoros</option>
                      <option value="50">Congo</option>
                      <option value="51">
                        Democratic Republic of the Congo
                      </option>
                      <option value="52">Cook Islands</option>
                      <option value="53">Costa Rica</option>
                      <option value="54">Cote D'Ivoire (Ivory Coast)</option>
                      <option value="55">Croatia</option>
                      <option value="56">Cuba</option>
                      <option value="57">Cyprus</option>
                      <option value="58">Czech Republic</option>
                      <option value="59">Denmark</option>
                      <option value="60">Djibouti</option>
                      <option value="61">Dominica</option>
                      <option value="62">Dominican Republic</option>
                      <option value="63">East Timor</option>
                      <option value="64">Ecuador</option>
                      <option value="65">Egypt</option>
                      <option value="66">El Salvador</option>
                      <option value="67">Equatorial Guinea</option>
                      <option value="68">Eritrea</option>
                      <option value="69">Estonia</option>
                      <option value="70">Ethiopia</option>
                      <option value="71">Falkland Islands</option>
                      <option value="72">Faroe Islands</option>
                      <option value="73">Fiji Islands</option>
                      <option value="74">Finland</option>
                      <option value="75">France</option>
                      <option value="76">French Guiana</option>
                      <option value="77">French Polynesia</option>
                      <option value="78">French Southern Territories</option>
                      <option value="79">Gabon</option>
                      <option value="80">Gambia The</option>
                      <option value="81">Georgia</option>
                      <option value="82">Germany</option>
                      <option value="83">Ghana</option>
                      <option value="84">Gibraltar</option>
                      <option value="85">Greece</option>
                      <option value="86">Greenland</option>
                      <option value="87">Grenada</option>
                      <option value="88">Guadeloupe</option>
                      <option value="89">Guam</option>
                      <option value="90">Guatemala</option>
                      <option value="91">Guernsey and Alderney</option>
                      <option value="92">Guinea</option>
                      <option value="93">Guinea-Bissau</option>
                      <option value="94">Guyana</option>
                      <option value="95">Haiti</option>
                      <option value="96">
                        Heard Island and McDonald Islands
                      </option>
                      <option value="97">Honduras</option>
                      <option value="98">Hong Kong S.A.R.</option>
                      <option value="99">Hungary</option>
                      <option value="100">Iceland</option>
                      <option value="101">India</option>
                      <option value="102">Indonesia</option>
                      <option value="103">Iran</option>
                      <option value="104">Iraq</option>
                      <option value="105">Ireland</option>
                      <option value="106">Israel</option>
                      <option value="107">Italy</option>
                      <option value="108">Jamaica</option>
                      <option value="109">Japan</option>
                      <option value="110">Jersey</option>
                      <option value="111">Jordan</option>
                      <option value="112">Kazakhstan</option>
                      <option value="113">Kenya</option>
                      <option value="114">Kiribati</option>
                      <option value="115">North Korea</option>
                      <option value="116">South Korea</option>
                      <option value="117">Kuwait</option>
                      <option value="118">Kyrgyzstan</option>
                      <option value="119">Laos</option>
                      <option value="120">Latvia</option>
                      <option value="121">Lebanon</option>
                      <option value="122">Lesotho</option>
                      <option value="123">Liberia</option>
                      <option value="124">Libya</option>
                      <option value="125">Liechtenstein</option>
                      <option value="126">Lithuania</option>
                      <option value="127">Luxembourg</option>
                      <option value="128">Macau S.A.R.</option>
                      <option value="129">Macedonia</option>
                      <option value="130">Madagascar</option>
                      <option value="131">Malawi</option>
                      <option value="132">Malaysia</option>
                      <option value="133">Maldives</option>
                      <option value="134">Mali</option>
                      <option value="135">Malta</option>
                      <option value="136">Man (Isle of)</option>
                      <option value="137">Marshall Islands</option>
                      <option value="138">Martinique</option>
                      <option value="139">Mauritania</option>
                      <option value="140">Mauritius</option>
                      <option value="141">Mayotte</option>
                      <option value="142">Mexico</option>
                      <option value="143">Micronesia</option>
                      <option value="144">Moldova</option>
                      <option value="145">Monaco</option>
                      <option value="146">Mongolia</option>
                      <option value="147">Montenegro</option>
                      <option value="148">Montserrat</option>
                      <option value="149">Morocco</option>
                      <option value="150">Mozambique</option>
                      <option value="151">Myanmar</option>
                      <option value="152">Namibia</option>
                      <option value="153">Nauru</option>
                      <option value="154">Nepal</option>
                      <option value="155">
                        Bonaire, Sint Eustatius and Saba
                      </option>
                      <option value="156">Netherlands</option>
                      <option value="157">New Caledonia</option>
                      <option value="158">New Zealand</option>
                      <option value="159">Nicaragua</option>
                      <option value="160">Niger</option>
                      <option value="161">Nigeria</option>
                      <option value="162">Niue</option>
                      <option value="163">Norfolk Island</option>
                      <option value="164">Northern Mariana Islands</option>
                      <option value="165">Norway</option>
                      <option value="166">Oman</option>
                      <option value="167">Pakistan</option>
                      <option value="168">Palau</option>
                      <option value="169">
                        Palestinian Territory Occupied
                      </option>
                      <option value="170">Panama</option>
                      <option value="171">Papua new Guinea</option>
                      <option value="172">Paraguay</option>
                      <option value="173">Peru</option>
                      <option value="174">Philippines</option>
                      <option value="175">Pitcairn Island</option>
                      <option value="176">Poland</option>
                      <option value="177">Portugal</option>
                      <option value="178">Puerto Rico</option>
                      <option value="179">Qatar</option>
                      <option value="180">Reunion</option>
                      <option value="181">Romania</option>
                      <option value="182">Russia</option>
                      <option value="183">Rwanda</option>
                      <option value="184">Saint Helena</option>
                      <option value="185">Saint Kitts And Nevis</option>
                      <option value="186">Saint Lucia</option>
                      <option value="187">Saint Pierre and Miquelon</option>
                      <option value="188">
                        Saint Vincent And The Grenadines
                      </option>
                      <option value="189">Saint-Barthelemy</option>
                      <option value="190">Saint-Martin (French part)</option>
                      <option value="191">Samoa</option>
                      <option value="192">San Marino</option>
                      <option value="193">Sao Tome and Principe</option>
                      <option value="194">Saudi Arabia</option>
                      <option value="195">Senegal</option>
                      <option value="196">Serbia</option>
                      <option value="197">Seychelles</option>
                      <option value="198">Sierra Leone</option>
                      <option value="199">Singapore</option>
                      <option value="200">Slovakia</option>
                      <option value="201">Slovenia</option>
                      <option value="202">Solomon Islands</option>
                      <option value="203">Somalia</option>
                      <option value="204">South Africa</option>
                      <option value="205">South Georgia</option>
                      <option value="206">South Sudan</option>
                      <option value="207">Spain</option>
                      <option value="208">Sri Lanka</option>
                      <option value="209">Sudan</option>
                      <option value="210">Suriname</option>
                      <option value="211">
                        Svalbard And Jan Mayen Islands
                      </option>
                      <option value="212">Swaziland</option>
                      <option value="213">Sweden</option>
                      <option value="214">Switzerland</option>
                      <option value="215">Syria</option>
                      <option value="216">Taiwan</option>
                      <option value="217">Tajikistan</option>
                      <option value="218">Tanzania</option>
                      <option value="219">Thailand</option>
                      <option value="220">Togo</option>
                      <option value="221">Tokelau</option>
                      <option value="222">Tonga</option>
                      <option value="223">Trinidad And Tobago</option>
                      <option value="224">Tunisia</option>
                      <option value="225">Turkey</option>
                      <option value="226">Turkmenistan</option>
                      <option value="227">Turks And Caicos Islands</option>
                      <option value="228">Tuvalu</option>
                      <option value="229">Uganda</option>
                      <option value="230">Ukraine</option>
                      <option value="231">United Arab Emirates</option>
                      <option value="232">United Kingdom</option>
                      <option value="233">United States</option>
                      <option value="234">
                        United States Minor Outlying Islands
                      </option>
                      <option value="235">Uruguay</option>
                      <option value="236">Uzbekistan</option>
                      <option value="237">Vanuatu</option>
                      <option value="238">Vatican City State (Holy See)</option>
                      <option value="239">Venezuela</option>
                      <option value="240">Vietnam</option>
                      <option value="241">Virgin Islands (British)</option>
                      <option value="242">Virgin Islands (US)</option>
                      <option value="243">Wallis And Futuna Islands</option>
                      <option value="244">Western Sahara</option>
                      <option value="245">Yemen</option>
                      <option value="246">Zambia</option>
                      <option value="247">Zimbabwe</option>
                      <option value="248">Kosovo</option>
                      <option value="249">Curaçao</option>
                      <option value="250">Sint Maarten (Dutch part)</option>
                    </select>
                  </div>
                </div> */}

                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="State" class="required custom-label">
                        State
                      </label>
                      <input
                        type="tel"
                        class="form-control"
                        id="State"
                        placeholder="राज्य"
                        name="State"
                        value={valuesetUserDeatils?.state}
                        // maxlength="6"
                        //   onChange={(e) => {
                        //     hendleToChange(e);
                        //   }}
                        onkeypress="javascript:return isNumber(event)"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="City" class="custom-label">
                        District
                      </label>
                      <input
                        type="tel"
                        class="form-control"
                        id="district"
                        placeholder="पिन कोड"
                        name="district"
                        value={valuesetUserDeatils?.district}
                        // maxlength="6"
                        //   onChange={(e) => {
                        //     hendleToChange(e);
                        //   }}
                        onkeypress="javascript:return isNumber(event)"
                      />
                      {/* <select
                      class="form-control"
                      name="city_id"
                      id="city_id"
                      required=""
                    >
                      <option value="" class="city_names">
                        शहर
                      </option>
                    </select> */}
                    </div>
                  </div>

                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="address" class="custom-label">
                        Address
                      </label>
                      <textarea
                        onChange={(e) => {
                          hendleToChange(e);
                        }}
                        class="form-control"
                        name="address"
                        id="address"
                        placeholder="पूरा पता"
                      ></textarea>
                    </div>
                  </div>

                  {/* <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="Volunteer" class="custom-label">
                        Become a Volunteer/स्वयंसेवक बनें
                      </label>
                      <select
                        class="form-control"
                        name="volunteer"
                        onChange={(e) => {
                          hendleToChange(e);
                        }}
                        required=""
                      >
                        <option>Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <label for="Volunteer" class="custom-label">
                        Donation Period
                      </label>
                      <select
                        class="form-control"
                        name="donation_period"
                        id="donation_period"
                        required=""
                        onChange={(e) => {
                          hendleToChange(e);
                        }}
                      >
                        <option value="Daily">Daily</option>
                        <option value="Fortnight">Fortnight</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Half-Yearly">Half Yearly</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                    </div>
                  </div> */}

                  {/* <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="name" class="custom-label">
                      Donation Date
                    </label>
                    <input
                      type="date"
                      class="form-control text_valid"
                      id="donation_date"
                      name="donation_date"
                      value="2024-08-23"
                      onChange={(e) => {
                        hendleToChange(e);
                      }}
                    />
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="name" class="custom-label">
                      Time
                    </label>
                    <input
                      type="time"
                      class="form-control"
                      id="currnt_time"
                      name="currnt_time"
                      value=""
                      required=""
                    />
                  </div>
                </div> */}

                  <div class="row m-2 p-3">
                    <div class="col-md-12 text-center">
                      <button
                        type="button"
                        onclick="CheckValidation()"
                        class="btn btn-primary student_detail_btn"
                        onClick={(e) => {
                          hendleToSubmit(e);
                        }}
                      >
                        submit
                      </button>
                      <br />
                      <button
                        type="submit"
                        id="sumbit_btn"
                        style={{ opacity: "0" }}
                      >
                        submit{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* <div id="volunteerCard">
            <div class="row">
              <form
                action="https://devmalaseri.com/volunteer_add"
                method="post"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="7bBV4fPHY6iDJMUgJ5CgNKkg9xBkUP5C1PcDnWPD"
                />{" "}
                <div class="shadow-lg p-4 mb-4 bg-white contact_form">
                  <input
                    type="hidden"
                    class="form-control"
                    id="admission_no"
                    name="admission_no"
                    placeholder="student.Admission No."
                    readonly=""
                    value="5"
                    onkeypress="javascript:return isNumber(event)"
                  />
                  <input type="hidden" name="form_type" value="Volunteer" />

                  <div class="row">
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="name" class="custom-label">
                          Volunteer Name
                        </label>
                        <input
                          type="text"
                          class="form-control text_valid"
                          id="name"
                          placeholder="नाम"
                          name="name"
                          value=""
                          onkeydown="return /[a-zA-Z ]/i.test(event.key)"
                        />
                        <span
                          class="invalid-feedback"
                          id="name_error"
                          role="alert"
                        >
                          <strong> Full Name can not be left blank</strong>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="name" class="custom-label">
                          Father Name
                        </label>
                        <input
                          type="text"
                          class="form-control text_valid"
                          id="father_name"
                          placeholder="पिता का नाम"
                          name="father_name"
                          value=""
                          onkeydown="return /[a-zA-Z ]/i.test(event.key)"
                        />
                        <span
                          class="invalid-feedback"
                          id="father_name_error"
                          role="alert"
                        >
                          <strong> Father Name can not be left blank</strong>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="mobile" class="custom-label">
                          Mobile
                        </label>
                        <input
                          type="tel"
                          class="form-control"
                          id="mobile"
                          placeholder="मोबाइल नं."
                          name="mobile"
                          value=""
                          pattern="[0-9]*"
                          maxlength="10"
                          onkeypress="javascript:return isNumber(event)"
                        />
                        <span
                          class="invalid-feedback"
                          id="mobile_error"
                          role="alert"
                        >
                          <strong> Mobile can not be left blank</strong>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="email" class="custom-label">
                          E-mail
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="ई-मेल"
                          name="email"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="qualification" class="custom-label">
                          Qualification
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="qualification"
                          placeholder="योग्यता"
                          name="qualification"
                          value=""
                        />
                      </div>
                    </div>

                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="village_city" class="custom-label">
                          Village
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="village_city"
                          placeholder="गाँव का नाम"
                          name="village_city"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="pincode" class="custom-label">
                          Pincode
                        </label>
                        <input
                          type="tel"
                          class="form-control"
                          id="pincode"
                          placeholder="पिन कोड"
                          name="pincode"
                          value=""
                          maxlength="6"
                          onkeypress="javascript:return isNumber(event)"
                        />
                        <span
                          class="invalid-feedback"
                          id="amount_error"
                          role="alert"
                        >
                          <strong> Amount can not be left blank</strong>
                        </span>
                      </div>
                    </div>

                    <div class="col-md-4 col-12">
                      <div class="form-group">
                        <label for="address" class="custom-label">
                          Address
                        </label>
                        <textarea
                          class="form-control"
                          name="address"
                          id="address"
                          placeholder="पूरा पता"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row m-2 p-3">
                    <div class="col-md-12 text-center">
                      <button type="submit" class="btn btn-primary">
                        submit{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default DonationNow;
