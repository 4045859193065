import React from "react";
import YouTube from "react-youtube";
const getYouTubeVideoId = (url) => {
  const urlObj = new URL(url);

  if (urlObj.pathname.startsWith("/shorts")) {
    // If the path is /shorts, extract the last part of the URL as the video ID
    return urlObj.pathname.split("/shorts/")[1];
  }

  // Otherwise, extract the video ID from the 'v' query parameter (standard YouTube video)
  return urlObj.searchParams.get("v");
};
const YouTubeShorts = ({ videoUrl }) => {
  const videoId = getYouTubeVideoId(videoUrl);
  const opts = {
    height: "560", // Standard height for Shorts (adjust if needed)
    width: "315", // Standard width for vertical video (9:16 ratio)
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      rel: 0,
      controls: 0, // Hides controls for a cleaner look like Shorts
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh", // Centers the video vertically
        backgroundColor: "#000", // Black background to match YouTube feel
      }}
    >
      <YouTube videoId={videoId} opts={opts} />
    </div>
  );
};

export default YouTubeShorts;
